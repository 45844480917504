import requests from "./request";



// 登录
export function login(data) {
    return requests({
      url: '/sys/login',
      method: 'post',
      data
    })
  }
// 验证码
  export function getCodeInfo(now) {
    return requests({
      url: '/sys/randomColorImage/1629428467008?_t='+now,
      method: 'get',
    })
  }
  // 首页信息侧数据
  export function displayInfo(data) {
    return requests({
      url: '/zh-applet-admin/appmana/bigScreen/displayInfo',
      method: 'post',
      headers: {
        'X-Access-Token':window.sessionStorage.getItem("token")
      },
      data
    })
  }
  // 首页站点侧数据
  export function stationInfo(data) {
    return requests({
      url: '/zh-applet-admin/appmana/bigScreen/stationInfo',
      method: 'post',
      headers: {
        'X-Access-Token':window.sessionStorage.getItem("token")
      },
      data
    })
  }
  // 粪污台账
  export function fwlist(pageNo,pageSize) {
    return requests({
      url: '/zh-applet-admin/appmana/survFecesLedger/list?pageNo='+1+'&pageSize='+200,
      method: 'get',
      headers: {
        'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
  // 农田覆膜
  export function ntfmlist(pageNo,pageSize) {
    return requests({
      url: '/zh-applet-admin/appmana/survMulchRecord/list?pageNo='+1+'&pageSize='+200,
      method: 'get',
      headers: {
        'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
    // 监测站实时数据
    export function getNewestData(data) {
      return requests({
        url: '/zh-applet-admin/appmana/bigScreen/getNewestData',
        method: 'post',
        headers: {
          'X-Access-Token':window.sessionStorage.getItem("token")
        },
        data
      })
    }
    // 污染物字典
  export function getPollutionDict() {
    return requests({
      url: '/zh-applet-admin/appmana/bigScreen/getPollutionDict',
      method: 'get',
      headers: {
        'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
  // 污染治理效果折线图
  export function getIndexSummry(stationCode) {
    return requests({
      url: '/zh-applet-admin/appmana/bigScreen/getIndexSummry?stationCode='+stationCode,
      method: 'post',
      headers: {
        'X-Access-Token':window.sessionStorage.getItem("token")
      },
      stationCode
    })
  }

   // 站点详情
   export function getStationDetail(stationCode) {
    return requests({
      url: '/zh-applet-admin/appmana/bigScreen/getStationDetail?stationCode='+stationCode,
      method: 'post',
      headers: {
        'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }

    // 站点详情
   export function getStationDisplay(stationCode) {
    return requests({
      url: '/zh-applet-admin/appmana/bigScreen/getStationDisplay?stationCode='+stationCode,
      method: 'get',
      headers: {
        'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }

     // 各个站坐标点
  export function getAllstationAndPestLight(isShowLight) {
    return requests({
      url: '/zh-applet-admin/appmana/bigScreen/getAllstationAndPestLight?isShowLight='+isShowLight,
      method: 'get',
      headers: {
        'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
  // 查询设备下各检测项的历史数据
  export function survItemSummary(obj) {
    return requests({
      url: '/appmana/bigScreen/survItemSummary',
      headers: {
        'X-Access-Token':window.sessionStorage.getItem("token")
      },
      method: 'post',
      data:obj
    })
  }