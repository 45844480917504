import Vue from 'vue'
import App from './App.vue'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
 
Vue.use(ElementUI);

// 引入路由
import router from '@/router'
// 引入动态样式
import 'animate.css';

// 地图
// 引入vue-amap
import VueAMap from 'vue-amap';
// 初始化vue-amap
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '661617fe39e495f25f7e7be2f5b88d35',
  plugin: [
    "AMap.Autocomplete", //输入提示插件
    "AMap.PlaceSearch", //POI搜索插件
    "AMap.Scale", //右下角缩略图插件 比例尺
    "AMap.OverView", //地图鹰眼插件
    "AMap.ToolBar", //地图工具条
    "AMap.MapType", //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    "AMap.PolyEditor", //编辑 折线多，边形
    "AMap.CircleEditor", //圆形编辑器插件
    "AMap.Geolocation" //定位控件，用来获取和展示用户主机所在的经纬度位置
  ],
  v: '2.0'
});

// 动态加载数据
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

// 默认全屏样式
import fullscreen from 'vue-fullscreen'
Vue.use(fullscreen)

// 图片放大
import Viewer from 'v-viewer' // 引入js
import 'viewerjs/dist/viewer.css' // 引入css
Vue.use(Viewer) // 默认配置写法


// 视频监控
import  EZUIKit from 'ezuikit-js';
Vue.use(EZUIKit)

// 路由守卫
import { Message } from 'element-ui';

Vue.prototype.$message = Message;

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  const requireAuth = to.meta.requireAuth
  // 判断该路由是否需要登录权限
  if (requireAuth) {
    if (window.sessionStorage.getItem('token')) {
      next()
    } else {
      // Message({
      //   message: "用户未登录",
      //   type: 'waring'
      // }),
      next('/Login')
    }
  } else {
    next()  // 确保一定要有next()被调用
  }
})
// 路由守卫完成

import $ from 'jquery'

window.jQuery = $;
window.$ = $;


// import {login} from '@/api';
// login();

new Vue({
  render: h => h(App),
  // 注册路由
  router
}).$mount('#app')
