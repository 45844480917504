<template>
	<ScaleBox
      :width="1920"
      :height="1080"
      bgc="transparent"
      :delay="100"
      :isFlat="true"
	  @scaleChange ="scaleChange"
    >
		<div
			class="main"
			v-loading="loading"
			element-loading-text="加载中"
			element-loading-background="rgba(0, 0, 0, 0.8)"
		>
			<div class="nav">
				<span class="nav_ti nav_mag">河津市农业面源污染在线监测平台</span>
				<span class="nav_tit">治理效果评价与预警一张图</span>
			</div>
			<div class="nav_btn">
				<div class="btn_left">
					<span class="btn_left_time"> {{ nowTime }}</span> <span class="btn_left_tianq" style="z-index: 9999;"><hefentianqi /></span>

				</div>
				<div class="btn_right">
					<img src="@/assets/image/quanping.png"  class="quanping" v-if="fullscreen==false" @click="screen" alt="">
					<img src="@/assets/image/quanpingtui.png"  class="quanping"  @click="screen" alt="" v-else>
					<span class="btn_right_time"><img src="@/assets/image/header.png" @click="dialogVisible = true" class="peoimg" alt=""></span>
				</div>
			</div>
			<!-- 退出登录弹窗 -->
			<div class="login logintc">
				<el-dialog
				title="退出登录"
				:visible.sync="dialogVisible"
				width="10%"
				>
					<span class="infornameclas">欢迎您，{{inforname}}！</span>
					<el-button type="primary" @click="handleClosetuichu" class="logintc_tc">
						<img src="@/assets/image/tuichu.png" @click="dialogVisible = true" class="tuichu" alt="">
						退出登录
					</el-button>
					<img src="@/assets/image/sanjiao.png"  class="sanjiao" alt="">
				</el-dialog>

			</div>

			<div class="content">
				<!-- <button @click="isShowBtn" style="position: fixed;top:5.2vw;left:26%;z-index: 999;">切换</button> -->
				<img src="@/assets/image/qiehuan2.png" @click="isShowBtn" style="position: fixed;top:5.2vw;right:26%;z-index: 999;width:12vw;cursor: pointer;" alt="" v-if="isShow==true" >
				<img src="@/assets/image/qiehuan.png" @click="isShowBtn" style="position: fixed;top:5.2vw;right:3%;z-index: 999;width:12vw;cursor: pointer;" alt="" v-else>
				<!-- <img src="@/assets/image/sbdscd.png" @click="sbdscdBtn" style="position: fixed;top:5.2vw;left:26%;z-index: 999;width:12vw;cursor: pointer;" alt="" > -->

				<el-tooltip content="杀虫灯" effect="light" placement="top">
					<el-switch
						v-model="switchValue"
						active-color="#13ce66"
						inactive-color="#ff4949"
						@change="switchBtn"
						v-if="isShow==true"
						style="position: fixed;bottom:17.2vw;right:25%;z-index: 999;">
					</el-switch>
					<el-switch
						v-model="switchValue"
						active-color="#13ce66"
						inactive-color="#ff4949"
						@change="switchBtn"
						v-else
						style="position: fixed;bottom:6.2vw;right:3%;z-index: 999;">
					</el-switch>
				</el-tooltip>
				<div id="container" v-if="fullscreen==false" style="width:98%;height:100%;resize:both; position: absolute;z-index:0;top:0px;left: 1%;transform:scale(1.05,1.05);"></div>
				<div id="container" v-else style="width:98%;height:100%;resize:both; position: absolute;z-index:0;top:0px;left: 1%;"></div>
				<transition enter-active-class="animate__animated animate__backInLeft" leave-active-class="animate__animated animate__backOutLeft">
					<template v-if="isShow" >
						<div class="baseBoxLeft left baseBoxHjleft">
							<img src="@/assets/image/kuang_zuoshang.png" class="in2con_zlf" alt="">
							<img src="@/assets/image/kuang_youshang.png" class="in2con_zys" alt="">
							<img src="@/assets/image/kuang_zuoxia.png" class="in2con_zzx" alt="">
							<img src="@/assets/image/kuang_youxia.png" class="in2con_zyx" alt="">
							<img src="@/assets/image/sanguang.png" class="sanguang" alt="">
							<img src="@/assets/image/sanguang.png" class="sanguangbt" alt="">
							<div>
								<div class="in1con_lf_tit ">
									<span class="in1con_lf_titshow1">{{briefconCode}}</span>
									<img src="@/assets/image/title_bg.png" class="in1con_lf_titimg" alt="">
								</div>
								<div class="home_lcgk" style="width: 88%;">
									<vue-seamless-scroll :data="briefconValue" class="wp-1 hp-1" :class-option="showOption">
											<p v-for="item in briefconValue" :key="item.id">{{item.detailValue}}</p>
									</vue-seamless-scroll>
								</div>
								<div class="in1con_lf_img">
									<div class="in1con_lf_imglf animate__animated animate__delay-1s animate__infinite animate__slow animate__pulse">
										<img src="@/assets/image/xmgk01.png" class="in1con_lf_imgone" alt="">
										<span class="in1con_lf_imglf_tit">农田面源污染监测站</span>
										<span class="in1con_lf_imglf_num"> <animate-number  class="in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="stationCount0" :key="stationCount0" duration="3000"></animate-number>个 </span>
									</div>
									<div class="in1con_lf_imglf animate__animated animate__delay-1s animate__infinite animate__slow animate__pulse">
										<img src="@/assets/image/xmgk02.png" class="in1con_lf_imgone" alt="">
										<span class="in1con_lf_imglf_tit">畜禽养殖污染监测站</span>
										<span class="in1con_lf_imglf_num"><animate-number class="in1con_lf_imglf_num_jix" ref="reNum" from="0"  :to="stationCount1" :key="stationCount1" duration="3000"></animate-number>个</span>
									</div>
									<div class="in1con_lf_imglf animate__animated animate__delay-1s animate__infinite animate__slow animate__pulse">
										<img src="@/assets/image/xmgk03.png" class="in1con_lf_imgone" alt="">
										<span class="in1con_lf_imglf_tit">太阳能诱虫灯</span>
										<span class="in1con_lf_imglf_num"><animate-number class="in1con_lf_imglf_num_jix" ref="reNum"  from="0" :to="pestLightCount" :key="pestLightCount" duration="3000"></animate-number>盏</span>
									</div>
								</div>
							</div>
							<div style="background: #0d4e62;height: 1px;width: 94%;margin: 0 auto;margin-top: 0.4vw;"></div>
							<div>
								<div class="in1con_lf_tit " >
									<span class="in1con_lf_titshow1">气候环境</span>
									<img src="@/assets/image/title_bg.png" class="in1con_lf_titimg" alt="">
								</div>
								<div class="qhhjcov">
									<div style="width:96%;margin:0 auto;margin-top:12px;">
										<div class="qhhjcov_lf" v-for="item in climate_env" :key="item.id">
											<img :src="urlimg+item.detailPics" class="qhhjcov_lfimg" alt="">
											<span class="qhhjcov_lf_tit">{{item.detailCode}}</span>
											<span class="qhhjcov_time"> <animate-number class="in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="item.detailValue" :key="item.detailValue" duration="3000"></animate-number>{{item.detailValue2}}</span>
										</div>
									</div>
								</div>
							</div>
							<div style="background: #0d4e62;height: 1px;width: 94%;margin: 0 auto;margin-top: 0.4vw;"></div>
							<div>
								<div class="in1con_lf_tit ">
									<span class="in1con_lf_titshow1">水利情况</span>
									<img src="@/assets/image/title_bg.png" class="in1con_lf_titimg" alt="">
								</div>
								<div class="in1con_lf_img">
									<div class="in1con_lf_imglf animate__animated animate__delay-1s animate__infinite animate__slow animate__pulse" v-for="legth in water_conser" :key="legth.id">
										<img :src="urlimg+legth.detailPics" class="in1con_lf_imgone" alt="">
										<span class="in1con_lf_imglf_tit">{{legth.detailCode}}</span>
										<span class="in1con_lf_imglf_num"> <animate-number  class="in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="legth.detailValue" :key="legth.detailValue" duration="3000"></animate-number>{{legth.detailValue2}}</span>
									</div>
								</div>
							</div>
							<div class="home_dlhj">
								<el-carousel arrow="never" height="150px">
									<el-carousel-item v-for="value in roll_info" :key="value.id">
										<div class="sx_cov" >
											<span class="sx_covtit">
												{{value.infoName}}
											</span>
											<ul v-for="ite in value.detailList" :key="ite.id">
												<li class="sx_cov_lt">
													<span class="sx_cov_lt_tit">{{ite.detailCode}}</span>
													<span class="sx_cov_lt_num">
														<animate-number class="in1con_lf_imglf_num_jix" ref="reNum"  from="0" :to="ite.detailValue" :key="ite.detailValue" duration="3000"></animate-number>
														{{ite.detailValue2}}
													</span>
												</li>
											</ul>
										</div>
									</el-carousel-item>
								</el-carousel>
							</div>
						</div>
					</template>
				</transition>
				<!-- 禽畜弹窗 -->
				<el-dialog :visible.sync="qcdtdialogTableVisible">
					<div class="jctc" id="modal1" >
						<div class="in1con_lf_tit ">
							<img src="@/assets/image/tctiimg.png" class="tctiimg" alt="">
							<span class="in1con_lf_titshow1" >{{qcdtinfor.stationName}}</span>
							<img src="@/assets/image/title_bg.png" class="in1con_lf_titimg" alt="">
						</div>
						<div class="jctcvban1">
							<img src="@/assets/image/jctctiao.png" class="jctctiao" alt="">
						</div>
						<div class="jctc_add">
							<img src="@/assets/image/jctcicon1.png" class="jctcicon1" alt="">
							<span class="jctcvban_tit">站点位置：{{qcdtinfor.stationLocation}}</span>
						</div>
						<div class="jctc_add">
							<img src="@/assets/image/jctcicon2.png" class="jctcicon1" alt="">
							<span class="jctcvban_tit">建站时间：{{qcdtinfor.buildTime}}</span>
						</div>
						<div class="jctc_add">
							<img src="@/assets/image/jctcicon3.png" class="jctcicon1" alt="">
							<span class="jctcvban_tit">设备数量：
								<!-- <span style="color: #f1ff0d;">{{qcdtinfor.deviceCount}}</span>  -->
								<span style="color: #f1ff0d;">5</span>
							</span>
						</div>
						<div class="jctc_add">
							<img src="@/assets/image/jctcicon4.png" class="jctcicon1" alt="">
							<span class="jctcvban_tit">监测指标</span>
						</div>
						<div style="margin-left: 3%;">
							<!-- survItemList -->
							<span class="jctc_text" v-for="(item,index) in qcdtinfor.survColorItems" :key="index" :style="{background:(item.color)}" >{{item.name}}</span>
							<!-- <span class="jctc_text" style="background: #445fb4;">总氮</span>
							<span class="jctc_text" style="background: #52ac2a;">硝钛氨</span>
							<span class="jctc_text" style="background: #ac4f2a;">空气温度</span>
							<span class="jctc_text" style="background: #ac4f2a;">空气湿度</span>
							<span class="jctc_text" style="background: #4c9bb5;">风速</span>
							<span class="jctc_text" style="background: #6a4cb5;">风向</span>
							<span class="jctc_text" style="background: #4cb585;">大气压</span>
							<span class="jctc_text" style="background: #ad8f49;">太阳全辐射</span>
							<span class="jctc_text" style="background: #52ac2a;">土壤温度</span>
							<span class="jctc_text" style="background: #4c9bb5;">土壤盐分</span> -->
						</div>
						<img src="@/assets/image/ljjr.png" class="ljjr" @click="addTask3(qcdtinfor)" alt="">
					</div>
				</el-dialog>
				<div v-if="isShow==true">
					<div class="scdlist" @click="sbdscdBtn" style="cursor: pointer;">
						<img src="@/assets/image/xcd.png" class="xcdimg" alt="">
						<span class="scdlist_tit">联网式太阳能杀虫灯</span>
					</div>
					<div class="scdlist2">
						<img src="@/assets/image/xcd2.png" class="xcd2img" alt="">
						<span class="scdlist_tit">农田面源污染监测站</span>
					</div>
					<div class="scdlist3">
						<img src="@/assets/image/xcd3.png" class="xcdimg" alt="">
						<span class="scdlist_tit">畜禽养殖污染监测站</span>
					</div>
					<div class="scdlist4">
						<img src="@/assets/image/xcd4.png" class="xcdimg" alt="">
						<span class="scdlist_tit">企业站点</span>
					</div>
				</div>
				<div v-else>
					<div class="scdlistpos" @click="sbdscdBtn" style="cursor: pointer;">
						<img src="@/assets/image/xcd.png" class="xcdimg" alt="">
						<span class="scdlist_tit">联网式太阳能杀虫灯</span>
					</div>
					<div class="scdlist2pos">
						<img src="@/assets/image/xcd2.png" class="xcd2img" alt="">
						<span class="scdlist_tit">农田面源污染监测站</span>
					</div>
					<div class="scdlist3pos">
						<img src="@/assets/image/xcd3.png" class="xcdimg" alt="">
						<span class="scdlist_tit">畜禽养殖污染监测站</span>
					</div>
					<div class="scdlist4pos">
						<img src="@/assets/image/xcd4.png" class="xcdimg" alt="">
						<span class="scdlist_tit">企业站点</span>
					</div>
				</div>
				<transition enter-active-class="animate__animated animate__backInUp" leave-active-class="animate__animated animate__backOutDown">
					<template v-if="isShow">
						<div class="zlxg_btm">
							<img src="@/assets/image/kuang_zuoshang.png" class="in2con_zlf" alt="">
							<img src="@/assets/image/kuang_youshang.png" class="in2con_zys" alt="">
							<img src="@/assets/image/kuang_zuoxia.png" class="in2con_zzx" alt="">
							<img src="@/assets/image/kuang_youxia.png" class="in2con_zyx" alt="">
							<img src="@/assets/image/sanguang.png" class="sanguang2" alt="">
							<img src="@/assets/image/sanguang.png" class="sanguangbt2" alt="">
							<div>
								<span class="zywr_titshow">重要污染物治理效果</span>
								<el-tabs v-model="activeName" type="card" @tab-click="handleClick" >
									<el-tab-pane v-for="item in stationInfoList" :key="item.id" :label="item.stationName" :name="item.name" lazy >
										<template v-if="item.name == 0||item.name==1">
											<div id="myChart"  style="height:12vw;width:100%;" v-if="activeName == item.name"></div>
										</template>
										<template v-else>
											<div id="zlxgChart"  style="height:12vw;width:100%;" v-if="activeName == item.name"></div>
										</template>

									</el-tab-pane>
								</el-tabs>
							</div>
						</div>
					</template>
				</transition>
				<transition enter-active-class="animate__animated animate__backInRight" leave-active-class="animate__animated animate__backOutRight">
					<template v-if="isShow" >
						<div class="leftBox right baseBoxHjleft" style="width:22.5%;overflow: hidden;">
							<img src="@/assets/image/kuang_zuoshang.png" class="in2con_zlf" alt="">
							<img src="@/assets/image/kuang_youshang.png" class="in2con_zys" alt="">
							<img src="@/assets/image/kuang_zuoxia.png" class="in2con_zzx" alt="">
							<img src="@/assets/image/kuang_youxia.png" class="in2con_zyx" alt="">
							<img src="@/assets/image/sanguang.png" class="sanguang" alt="">
							<img src="@/assets/image/sanguang.png" class="sanguangbt" alt="">
							<div>
								<div class="home_dlhj">
									<el-carousel  :autoplay="false" indicator-position="none" arrow="always" height="320px" >
										<el-carousel-item  v-for="item in stationInfoList" :key="item.id">
											<div class="in1con_lf_tit ">
												<span class="in1con_lf_titshow1 mywrcov_onehover" @click="addTask3(item)">{{item.stationName}}</span>
												<img src="@/assets/image/title_bg.png" class="in1con_lf_titimg" alt="">
											</div>
											<div class="jczcov">
												<viewer :images="item.deviceList" v-for="value in item.deviceList" :key="value.id">
													<div class="jczcov_lf" v-for="(imglist,index) in item.stationPic" :key="index">
															<img :src="urlimg+imglist"  class="jczimg" alt="">
													</div>
												</viewer>
											</div>
											<div class="jczbtm">
												<vue-seamless-scroll :data="item.wrwcode" class="wp-1 hp-1" :class-option="classWrwcod">
														<div class="jczbtm_lf" v-for="valist in item.wrwcode" :key="valist.index" @click="tczxtc(item.stationCode,valist.tylename)">
															<img :src="urlimg + valist.icon" class="tpimg" alt="">
															<div class="jczbtm_lf_rt">
																<span class="jczbtm_lf_rttit">{{valist.name}}</span>
																<span class="jczbtm_lf_rtnum">
																	<animate-number  class="in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="valist.code" :key="valist.code" duration="3000"></animate-number>
																	<!-- <span class="in1con_lf_imglf_num_jix">{{valist.code}}</span> -->
																	{{valist.unit}}</span>
															</div>
														</div>
												</vue-seamless-scroll>

											</div>
										</el-carousel-item>
									</el-carousel>
								</div>
							</div>
							<div style="background: #0d4e62;height: 1px;width: 94%;margin: 0 auto;margin-top: 0.4vw;"></div>
							<div>
								<div class="in1con_lf_tit ">
									<span class="in1con_lf_titshow1">面源污染治理效果评价</span>
									<img src="@/assets/image/title_bg.png" class="in1con_lf_titimg" alt="">
								</div>
								<div class="mywrcov">
									<div class="mywrcov_one mywrcov_onehover" @click="tzqcalin">
										<span class="mywrcov_onetit">{{effect_assess0.detailCode}}</span>
										<span class="mywrcov_onenum">
											<span class="in1con_lf_imglf_num_jix mywrcov_onenumjx">{{effect_assess0.detailValue}}</span>
										</span>
									</div>
									<div class="mywrcov_two ">
										<span class="mywrcov_onetit">{{effect_assess1.detailCode}}</span>
										<span class="mywrcov_onenum"><span class="in1con_lf_imglf_num_jix mywrcov_onenumjx">{{effect_assess1.detailValue}}</span></span>
									</div>
									<div class="mywrcov_three mywrcov_onehover" @click="tzalin">
										<span class="mywrcov_onenum" style="margin-top: 1vw;"><span class="in1con_lf_imglf_num_jix mywrcov_onenumjx">{{effect_assess2.detailValue}}</span></span>
										<span class="mywrcov_onetit">{{effect_assess2.detailCode}}</span>
									</div>
									<div class="mywrcov_four ">
										<span class="mywrcov_onenum" style="margin-top: 1vw;"><span class="in1con_lf_imglf_num_jix mywrcov_onenumjx">{{effect_assess3.detailValue}}</span></span>
										<span class="mywrcov_onetit">{{effect_assess3.detailCode}}</span>
									</div>
									<div class="mywrcovcen">
										<!-- <img src="@/assets/image/chengg.png" class="mywrcovcen_tit" alt=""> -->
										<span class="mywrcovcen_tit" v-if="effect_assess4.detailValue=='成功'">优</span>
										<span class="mywrcovcen_tit" v-else>{{effect_assess4.detailValue}}</span>
										<span class="mywrcovcen_con">{{effect_assess4.detailCode}}</span>
									</div>
								</div>
							</div>
							<div style="background: #0d4e62;height: 1px;width: 94%;margin: 0 auto;margin-top: 0.4vw;"></div>
							<div>
								<div class="in1con_lf_tit ">
									<span class="in1con_lf_titshow1">监测站实时数据</span>
									<img src="@/assets/image/title_bg.png" class="in1con_lf_titimg" alt="">
								</div>
								<div style="height:15vw;overflow: hidden">
									<vue-seamless-scroll :data="listData" class="wp-1 hp-1" :class-option="classOption">
											<div class="jcsssj" v-for="(item, index) in listData" :key="index" >
												<img src="@/assets/image/xian.png" class="jcsssj_xian" alt="">
												<div class="jcsssj_rt" @click="tczxtc(item.stationCode,item.survItem)">
													<div style="float: left;width: 70%;">
														<span class="jcsssj_rt_tit" >{{item.stationName}}</span>
														<span class="jcsssj_rtbiao" :style="{background:(item.color)}" >{{item.name}}</span>
														<span class="jcsssj_rt_numsy" >
															<!-- <span class="in1con_lf_imglf_num_jix">{{item.value}}</span> -->
															<animate-number class="in1con_lf_imglf_num_jix" ref="myNum" from="0" :to="item.value" :key="item.value" duration="3000" :formatter="formatter"></animate-number>
															{{item.unit}}
														</span>
														<!-- <img src="@/assets/image/up.png" class="jcsssj_rt_up" alt=""> -->
													</div>
													<span class="jcsssj_rt_time">{{item.dataDateTime}}</span>
												</div>
											</div>
									</vue-seamless-scroll>
								</div>
							</div>
						</div>
					</template>
				</transition>
			</div>
			<!-- 台账弹窗 -->
			<el-dialog width="60%"  :visible.sync="dialogTableVisible" >
				<div class="tz_cov">
					   <img src="@/assets/image/guanbi.png" class="guanbiann" @click="guanbi" alt="">
						<div class="tz_cov_top">
							<span class="tz_cov_top_tit">农业废弃物台账</span>
						</div>
						<div class="tz_cov_left">
							<div class="tz_cov_left_tit">
								<span class="tz_cov_left_tit_text">主要农业废弃物类型</span>
							</div>
							<div class="tz_cov_left_bincov">
								<div class="tz_cov_left_bin">

								</div>
								<div id="main" class="tz_cov_left_bineca" ></div>
							</div>

						</div>
						<div class="tz_cov_right">
							<img src="@/assets/image/kuang_zuoshang.png" class="in2con_zlf" alt="">
							<img src="@/assets/image/kuang_youshang.png" class="in2con_zys" alt="">
							<img src="@/assets/image/kuang_zuoxia.png" class="in2con_zzx" alt="">
							<img src="@/assets/image/kuang_youxia.png" class="in2con_zyx" alt="">
							<img src="@/assets/image/sanguang.png" class="tzsanguang" alt="">
							<img src="@/assets/image/sanguang.png" class="tzsanguangbt" alt="">
							<div style="overflow:hidden;line-height:2vw;">
								<div class="tz_cov_left_tit" style="float:left;">
									<span class="tz_cov_left_tit_text">废弃农膜采样信息</span>
								</div>
								<span class="tz_cov_right_num">累计 <span class="tz_cov_right_num_big">{{ntfmnumbtotal}}</span> 条 </span>
							</div>
							<table class="tz_cov_right_tab">
								<tr class="tz_cov_right_tab_tit" style="width:100%;">
									<th style="width:20%;">采样时间</th>
									<th style="width:30%;">监测站</th>
									<th style="width:10%;">作物</th>
									<th style="width:30%;">农膜残留量(kg/h㎡)</th>
									<th style="width:10%;">覆膜年份</th>
								</tr>
								<img src="@/assets/image/noneNumber.png" class="noneNumber"  alt="" v-if="ntfmnumbtotal == 0">
								<template v-else>
									<tr class="tz_cov_right_tab_list" v-for="item in ntfmnumblist" :key="item.id">
											<td>{{item.sampTime}}</td>
											<td>{{item.stationName}}</td>
											<td>{{item.cropName}}</td>
											<td>{{item.mulchRemain}}</td>
											<td>{{item.mulchYear}}</td>
									</tr>
								</template>
							</table>

						</div>
					</div>
			</el-dialog>
			<!-- 台账禽畜弹窗 -->
			<el-dialog width="60%"  :visible.sync="qcdialogTableVisible" >
				<div class="tz_cov">
					 <img src="@/assets/image/guanbi.png" class="guanbiann" @click="qcguanbi" alt="">
						<div class="tz_cov_top">
							<span class="tz_cov_top_tit">畜禽粪污台账</span>
						</div>
						<div class="tz_cov_left">
							<div class="tz_cov_left_tit">
								<span class="tz_cov_left_tit_text">资源化利用方向</span>
							</div>
							<div class="tz_cov_left_bincov">
								<div class="tz_cov_left_bin">

								</div>
								<div id="main" class="tz_cov_left_bineca" ></div>
							</div>

						</div>
						<div class="tz_cov_right">
							<img src="@/assets/image/kuang_zuoshang.png" class="in2con_zlf" alt="">
							<img src="@/assets/image/kuang_youshang.png" class="in2con_zys" alt="">
							<img src="@/assets/image/kuang_zuoxia.png" class="in2con_zzx" alt="">
							<img src="@/assets/image/kuang_youxia.png" class="in2con_zyx" alt="">
							<img src="@/assets/image/sanguang.png" class="tzsanguang" alt="">
							<img src="@/assets/image/sanguang.png" class="tzsanguangbt" alt="">
							<div style="overflow:hidden;line-height:2vw;">
								<div class="tz_cov_left_tit" style="float:left;">
									<span class="tz_cov_left_tit_text">粪污台账</span>
								</div>
								<span class="tz_cov_right_num">累计 <span class="tz_cov_right_num_big">{{qcfmnumbtotal}}</span> 条 </span>
							</div>
							<table class="tz_cov_right_tab">
								<tr class="tz_cov_right_tab_tit" style="width:100%;">
									<th style="width:10%;">年份</th>
									<th style="width:20%;">养殖场名称</th>
									<th style="width:10%;">养殖品种</th>
									<th style="width:10%;">现有存栏</th>
									<th style="width:10%;">固体粪便</th>
									<th style="width:10%;">液体粪污</th>
									<th style="width:10%;">混合粪污</th>
									<th style="width:10%;">收入金额</th>
								</tr>
								<img src="@/assets/image/noneNumber.png" class="noneNumber"  alt="" v-if="qcfmnumbtotal == 0">
								<template v-else>
									<tr class="tz_cov_right_tab_list"  v-for="item in qcfmnumblist" :key="item.id">
										<td style="width:10%;">{{item.countYear}}</td>
										<td style="width:20%;">{{item.farmName}}</td>
										<td style="width:10%;">{{item.breedVariety}}</td>
										<td style="width:10%;">{{item.breedScale}}</td>
										<td style="width:10%;">{{item.fecesQuantity}}</td>
										<td style="width:10%;">{{item.urineQuantity}}</td>
										<td style="width:10%;">{{item.mixQuantity}}</td>
										<td style="width:10%;">{{item.chargeAmount}}</td>
									</tr>
								</template>
							</table>

						</div>
					</div>
			</el-dialog>
			<!-- 企业弹窗 -->
			<el-dialog :visible.sync="qyinfordtdialogTableVisible">
				<div class="tz_cov" style="height:25vw;">
					<img src="@/assets/image/guanbi.png" class="guanbiann" @click="qcguanbi" alt="">
					<div class="tz_cov_top">
						<span class="tz_cov_top_tit">企业简介</span>
					</div>
					<el-row>
						<el-col :span="12">
							<div class="grid-content bg-purple" style="width:80%;margin-left:10%;">
								<el-carousel indicator-position="outside">
									<el-carousel-item v-for="(item,index) in qyinformation.extPics" :key="index">
										<viewer :images="qyinformation.extPics">
											<img :src="urlimg + item" alt="" style="width:100%;height:100%;">
										</viewer>
									</el-carousel-item>
								</el-carousel>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="grid-content bg-purple-light">
								<span class="tctit">{{qyinformation.name}}</span>
								<span class="tcgldw">{{qyinformation.notes}}</span>
							</div>
						</el-col>
					</el-row>
				</div>
			</el-dialog>
			<!-- 曲线弹窗 -->
			<el-dialog
				width="70%"
				top="16vh"
				:visible.sync="trqxzxdialogTableVisible"
				:modal-append-to-body="false"
			>
				<div class="tz_cov" style="height: 35vw">
					<div style="width:100%;height:100%;margin:0 auto;position: absolute;top:0;left:0;z-index:444;background:rgba(0,0,0,0.4);" v-if="trbgzhuanq"></div>
					<img src="@/assets/image/loading.gif" alt="" style="width:150px;height:150px;margin:0 auto;position: absolute;top:280px;left:45%;z-index: 555;" v-if="trbgzhuanq">
					<img
						src="@/assets/image/guanbi.png"
						class="guanbiann"
						@click="qcguanbi"
						alt=""
					/>
					<div class="tz_cov_top">
						<span class="tz_cov_top_tit">{{ descriptionValue }}</span>
					</div>
					<div class="trwdcov jgdhei">
						<div class="trwdcov_top">
						<span class="trwdcov_top_tit">时间范围</span>
						<el-date-picker
							class="trwdcov_top_time"
							v-model="timetr"
							type="daterange"
							@blur="timetrpicker"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
						>
						</el-date-picker>
						<span class="trwdcov_top_tit">展示方式</span>

						<el-radio-group v-model="radiotr" @change="agreeChange">
							<el-radio label="1">折线</el-radio>
							<el-radio label="2">表格</el-radio>
						</el-radio-group>
						</div>
						<el-table
						:data="formListVlaue"
						v-if="trzx"
						height="480"
						border
						style="margin-top: 1vw; background: none"
						>
						<el-table-column prop="date" label="时间"> </el-table-column>
						<el-table-column prop="name" :label="'数值单位：' + unitValue">
						</el-table-column>
						</el-table>

						<template v-else>
						<div id="maintczxtc" style="width: 100%; height: 500px" v-if="trbg"></div>
						</template>
					</div>
					<img src="@/assets/image/kuang_zuoshang.png" class="in2con_zlf" alt="">
					<img src="@/assets/image/kuang_youshang.png" class="in2con_zys" alt="">
					<img src="@/assets/image/kuang_zuoxia.png" class="in2con_zzx" alt="">
					<img src="@/assets/image/kuang_youxia.png" class="in2con_zyx" alt="">
					<img src="@/assets/image/sanguang.png" class="tzsanguang" alt="">
					<img src="@/assets/image/sanguang.png" class="tzsanguangbt" alt="">
				</div>
			</el-dialog>
		</div>
    </ScaleBox>

</template>

<script>
import ScaleBox from 'vue2-scale-box'
import { displayInfo, stationInfo, fwlist, ntfmlist, getNewestData, getPollutionDict, getIndexSummry, getAllstationAndPestLight, survItemSummary } from '@/api';
import vueSeamlessScroll from 'vue-seamless-scroll'
import hefentianqi from '@/pages/weather/weather.vue'
export default {
  name: "Home",
  components: {
	vueSeamlessScroll,
	hefentianqi,
	ScaleBox
  },
  data(){
    return {
	  urlimg:'https://hjbs.sxcooh.com/zh-api/sys/common/static/',
	  isShow: true,
      nowTime: '',
	  briefconCode:"",
	  briefconValue:[],
	  climate_env:[],
	  water_conser:[],
	  roll_info:[],
	  stationInfoList:[],
	  wrwcode:[],
	  ntfmnumblist:[],
	  ntfmnumbtotal:'',
	  qcfmnumblist:[],
	  qcfmnumbtotal:'',
	  getPollutionDictCover:[],
	  effect_assess0:'',
	  effect_assess0Value:0,
	  effect_assess1:'',
	  effect_assess2:'',
	  effect_assess3:'',
	  effect_assess4:'',
	  pestLightCount:0,
	  stationCount0:0,
	  stationCount1:0,
	//   showData:[],
	  dialogTableVisible:false,
	  qcdialogTableVisible:false,
	  qcdtdialogTableVisible:false,
	  qyinfordtdialogTableVisible:false,
	  shipdialogTableVisible:false,
	  trqxzxdialogTableVisible:false,
	  trbg:null,
	  trzx:false,
      trbgzhuanq:false,
	  descriptionValue:null,
	  timetr: [new Date(), new Date()],
      radiotr: '1',
	  qyinformation:'',
	  listData: [],
	  activeName: '0',
	  NOSummry:[],
	  TNSummry:[],
	  TPSummry:[],
	  qcdtinfor:'',
	  resourcedirect:[],
	  dialogVisible: false,
	  inforname:'',
	  loading: true,
	  zoomData:14,
	  isShowLightNumber:'1',
	  switchValue:true,
	  fullscreen: false, //全屏
	  dataStr:'',
    };
  },
  created(){
	// 实时时间
    this.getNowTime();
	this.indexleft();
	this.getNewestDataList();
	this.getPollutionDictList();
	this.getIndexSummrycover();
	this.infor();
	this.indexinfor();
  },

	computed: {
		classOption() {
			return {
				step: 0.6, // 数值越大速度滚动越快
				limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
				hoverStop: true, // 是否开启鼠标悬停stop
				direction: 1, // 0向下 1向上 2向左 3向右
				openWatch: true, // 开启数据实时监控刷新dom
				singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
				singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
				waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
			};
		},
		classWrwcod() {
			return {
				step: 0.2, // 数值越大速度滚动越快
				limitMoveNum: 6, // 开始无缝滚动的数据量 this.dataList.length
				hoverStop: true, // 是否开启鼠标悬停stop
				direction: 1, // 0向下 1向上 2向左 3向右
				openWatch: true, // 开启数据实时监控刷新dom
				singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
				singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
				waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
			};
		},
		showOption() {
			return {
				step: 0.5, // 数值越大速度滚动越快
				limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
				hoverStop: true, // 是否开启鼠标悬停stop
				direction: 1, // 0向下 1向上 2向左 3向右
				openWatch: true, // 开启数据实时监控刷新dom
				singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
				singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
				waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
			};
		},
	},
	mounted() {
		setTimeout(() =>{
			this.$nextTick(() => {
				this.indexinfor();
			})
		}, 2000)
		// 实时时间
		setTimeout(() =>{
			this.$nextTick(() => {
				this.PestLight(this.zoomData,this.isShowLightNumber);
			})
		}, 2000)
	},
  methods:{
	formatter: function (num) {
        return num.toFixed(3)
      },
	sbdscdBtn(){
		var url = 'https://wlw.zklsfk.com/admin/index?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiLmsrPmtKXluILlhpzkuJrlhpzmnZHlsYAiLCJleHAiOjE2ODk2MjU0ODUsImlhdCI6MTY4ODEzMDI1NiwianRpIjoiNTcxMTY5MTAtODczZC00ZjI1LWFkNzItZjA2NWU3NmFkNDIwIn0.52iF21xidGFllgfcsSgN-cLlysI0W7Ad8ie48LsV9x4'
		//跳转1
		window.open(url,"_blank");
	},
	isShowBtn(){
		this.isShow = !this.isShow
		this.$nextTick(()=> { // 加载echarts图
			this.zxong()
			this.zxtwoong()
		})
		if (this.isShow==true) {
			this.zoomData = 14
			if (this.isShowLight==undefined) {
				let isShowLight = '1'
				this.PestLight(this.zoomData,isShowLight);

			} else {
				let isShowLight = this.isShowLight
				this.PestLight(this.zoomData,isShowLight);
			}

		} else {
			this.zoomData = 15
			if (this.isShowLight==undefined) {
				let isShowLight = '1'
				this.PestLight(this.zoomData,isShowLight);

			} else {
				let isShowLight = this.isShowLight
				this.PestLight(this.zoomData,isShowLight);
			}
		}

	},
	indexleft(){
		displayInfo().then(res=>{
			setTimeout(() =>{
					this.loading = false
			}, 1000)
			this.resourcedirect = res.result.resource_direct
			this.briefconCode = res.result.project_brief.detailList[0].detailCode
			this.briefconValue = res.result.project_brief.detailList
			this.pestLightCount = res.result.pestLightCount
			this.stationCount0 = res.result.stationCount[0].stationCount
			this.stationCount1 = res.result.stationCount[1].stationCount
			this.climate_env = res.result.climate_env.detailList
			this.water_conser = res.result.water_conser.detailList
			this.roll_info = res.result.roll_info
			this.effect_assess0 = res.result.effect_assess.detailList[0]
			this.effect_assess0Value = res.result.effect_assess.detailList[0].detailValue
			this.effect_assess1 = res.result.effect_assess.detailList[1]
			this.effect_assess2 = res.result.effect_assess.detailList[2]
			this.effect_assess3 = res.result.effect_assess.detailList[3]
			this.effect_assess4 = res.result.effect_assess.detailList[4]

		})
	},
	indexinfor(){
		stationInfo().then(res=>{
			this.stationInfoList = res.result
			if(this.stationInfoList){
				for(var i=0;i<this.stationInfoList.length;i++){
					let objlist = this.stationInfoList[i].newestData
					let arryobjlist = Object.keys(objlist).map(key => objlist[key])
					let newobjList = Object.keys(objlist)
					this.stationInfoList[i].wrwcode = []
					for(var j=0;j<this.getPollutionDictCover.length;j++){
						// Object.keys 获取字段名
						for(var k=0;k<newobjList.length;k++){
							if(this.getPollutionDictCover[j].code == newobjList[k]){
								this.stationInfoList[i].wrwcode.push({
									name:this.getPollutionDictCover[j].description,
									icon:this.getPollutionDictCover[j].icon,
									unit:this.getPollutionDictCover[j].unit,
									color:this.getPollutionDictCover[j].color,
									code:arryobjlist[k],
									tylename:newobjList[k]
								})
							}
						}
					}
				}
			}

		})
	},
	switchBtn(value){
		console.log("value++++++++",value)
		if (value == true) {
			let isShowLight = '1'
			this.isShowLight = isShowLight
			this.PestLight(this.zoomData,isShowLight)
		} else {
			let isShowLight = ''
			this.isShowLight = isShowLight
			this.PestLight(this.zoomData,isShowLight)
		}
	},
	scaleChange(e){
		console.log("ee",e)
	},
	// 历史数据曲线弹唱
    deviceSummaryApi(){
      let obj = {
        stationCode:this.stationCode,
        endTime:this.endTime,
        startTime:this.startTime,
        survItem:this.survItem,
        // auths:this.auths,
      }
      survItemSummary(obj).then(res=>{
        let that = this
        this.dataListVlaue = res.result.dataList
        this.timeListVlaue = res.result.timeList
        this.formListVlaue = res.result.formList
        this.unitValue = res.result.itemInfo.unit
        // this.limitStrValue = res.result.itemInfo.limitStr
        this.descriptionValue = res.result.itemInfo.description
        if (this.timeListVlaue) {
          setTimeout(() =>{
            that.$nextTick(() => {
              that.drawChartTc();
            })
          }, 700)
        }
      })
    },
	drawChartTc() {
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        let myChart1 = this.$echarts.init(document.getElementById("maintczxtc"));
        // 指定图表的配置项和数据
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#3c6ae1'
              }
            }
          },
          legend: {
            data: ['']
          },
          toolbox: {
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [  // 渐变颜色
              {
                offset: 0,
                color: 'rgba(85,111,253,0.70)',
                },
              {
                offset: 1,
                color: 'rgba(85,111,253,0.20)',
                  },
              ],
              global: false,
              },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: this.timeListVlaue.reverse(),
              axisLabel: {
                //x轴文字的配置
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                //x轴文字的配置
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
            }
          ],
          series: [
            {
              name: this.descriptionValue,
              animationDurationUpdate: 1000, // 更新动画时长为 1s
              animationEasingUpdate: 'quinticInOut', // 更新动画缓动效果为 'quinticInOut'
              type: 'line',
              stack: 'Total',
              smooth:true,
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              lineStyle: {
                color: '#3c6ae1' // 设置线的颜色为红色
              },
              data: this.dataListVlaue.reverse(),
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart1.clear();
        myChart1.setOption(option);
      },
	// 时间
	timetrpicker(data){
      this.startTime = data.displayValue[0],
      this.endTime = data.displayValue[1],
      this.deviceSummaryApi()
    },
	// 所有弹窗
    tczxtc(val,survItem){
		this.descriptionValue = ''
      this.stationCode = val
      this.survItem = survItem
      this.trqxzxdialogTableVisible = true
       this.trbgzhuanq = true
      setTimeout(() =>{
          this.deviceSummaryApi()
          this.trbgzhuanq = false
          this.trbg = true;
        }, 1500)
    },
	agreeChange(val){
        let that = this
        this.trbg = false;
        if (val=='1') {
          this.trbg = true
          this.trbgzhuanq = true
          this.trzx = false
          setTimeout(() =>{
            this.trbgzhuanq = false
            that.$nextTick(() => {
              that.deviceSummaryApi()
                // this.drawChartTc();
            })
          }, 1200)
        } else if(val=='2'){
          this.trbgzhuanq = true
          setTimeout(() =>{
            that.deviceSummaryApi()
            this.trbgzhuanq = false
            this.trzx = true
            this.trbg = false
          }, 1200)
        }

        that.btnstatus=(val==='1')?true:false;
    },
	PestLight(zoomnum,isShowLight){
		// let isShowLight = this.isShowLightNumber
		getAllstationAndPestLight(isShowLight).then(res=>{
			// 地图
			var map = new AMap.Map('container', {
			viewMode:'3D',
			resizeEnable: true,
			zoom: zoomnum,
			center:[110.632024, 35.548859],//默认的地图中心经纬度
			mapStyle: 'amap://styles/blue',
			dragEnable: true,//地图是否可通过鼠标拖拽平移，默认为true。此属性可被setStatus/getStatus 方法控制
			jogEnable: true,//地图是否使用缓动效果，默认值为true。此属性可被setStatus/getStatus 方法控制
			keyboardEnable: true,//地图是否可通过键盘控制,默认为true方向键控制地图平移，"+"和"-"可以控制地图的缩放，Ctrl+“→”顺时针旋转，Ctrl+“←”逆时针旋转。此属性可被setStatus/getStatus 方法控制
			animateEnable: true,//地图平移过程中是否使用动画（如调用panBy、panTo、setCenter、setZoomAndCenter等函数，将对地图产生平移操作，是否使用动画平移的效果），默认为true，即使用动画
			defaultCursor: 'pointer',
			turboMode: false,
			showBuildingBlock: true,//设置地图显示3D楼块效果，移动端也可使用。推荐使用。
			showIndoorMap: false, // 隐藏地图自带的室内地图图层
			expandZoomRange: true,//是否支持可以扩展最大缩放级别,和zooms属性配合使用设置为true的时候，zooms的最大级别在PC上可以扩大到20级，移动端还是高清19/非高清20
			forceVector: true,
			scale:(2,2.5)
		});
		map.on('complete',  (e) =>{
			// 创建 AMap.LabelsLayer 图层
			var layer = new AMap.LabelsLayer({
				zooms: [3, 20],
				zIndex: 1000,
				collision: false,
			});
			// 将图层添加到地图
			map.add(layer);
			var markers = [];
			var markerData = res.result;
			let LabelMarker = markerData.filter(ele=>ele.type == 'light');
			let Marker = markerData.filter(ele=>ele.type != 'light');

			LabelMarker.map((item,index)=>{
				let curData = {
					position:[item.longitude,item.latitude],
					icon:{
						// type: 'image',
						image:this.urlimg + item.picUrl,
						anchor:'bottom-center',
						type:item.type,
						name:item.name,
						ids:item.ids,
						extPics:item.extPics,
						notes:item.notes,
						zIndex: item.type=="orient"?999999:item.type=="cusLocaltion"?888888:1,
					},
					offset: new AMap.Pixel(item.width,item.height),//偏移量
				};
				var labelMarker = new AMap.LabelMarker(curData);

				markers.push(labelMarker);
				// else {
					// mouseover
					// labelMarker.on("click", (e) => {
					// 	console.log("eeeeeee",e)
					// 	 this.$notify({
					// 		title: item.name,
					// 		duration:1000
					// 	 });

					// })

				// }
			})
			Marker.map(item=>{
				let marker = new AMap.Marker({
					icon: this.urlimg + item.picUrl,
					position:[item.longitude,item.latitude],
					offset: new AMap.Pixel(item.width,item.height),//偏移量
					extData:{
						ids:item.ids,
						extPics:item.extPics,
						notes:item.notes,
						name:item.name,
					}
				});
				if (item.type=="livestock"||item.type=="orient") {
					marker.on("click", e => {
						
						let indexnumb = e.target.getExtData().ids.slice(-1);
						this.qcdtinfor = this.stationInfoList[indexnumb]
						this.qcdtdialogTableVisible = true
					})
				} else if(item.type=="cusLocaltion"){
					marker.on("click", e => {

						this.qyinformation= {
							extPics:e.target.getExtData().extPics,
							notes:e.target.getExtData().notes,
							name:e.target.getExtData().name,
						}
						console.log("this.qyinformation",this.qyinformation)
						this.qyinfordtdialogTableVisible = true
					})
				} 
				marker.setMap(map);
			})




			// 一次性将海量点添加到图层
			layer.add(markers);
			this.$forceUpdate()

		});
		})
	},
	tzalin(){
		this.dialogTableVisible = true
		this.fwlistcover()
		this.$nextTick(() => {
			var myChart = this.$echarts.init(document.getElementById('main'));
			//配置图表
			var option = {
				tooltip: {
					trigger: 'item'
				},
				legend: {
					bottom: '-2%',
					left: 'center',
					itemWidth: 20,
					itemHeight: 10,
					textStyle: {
					color: '#fff'  // 图例文字颜色
					},
				},
				series: [
					{
					name: '',
					type: 'pie',
					radius: ['40%', '50%'],
					avoidLabelOverlap: false,
					itemStyle: {
						// borderRadius: 10,
						// borderColor: '#fff',
						// borderWidth: 2
					},
					label: {
						show: false,
						position: 'center'
					},
					emphasis: {
						label: {
						show: false,
						fontSize: 40,
						fontWeight: 'bold'
						}
					},
					labelLine: {
						show: true
					},
					labelLine: {
						normal: {
							show: true   // show设置线是否显示，默认为true，可选值：true ¦ false
						}
					},
					// 设置值域的标签
					label: {
						normal: {
							position: 'outer',  // 设置标签位置，默认在饼状图外 可选值：'outer' ¦ 'inner（饼状图上）'
							// formatter: '{a} {b} : {c}个 ({d}%)'   设置标签显示内容 ，默认显示{b}
							// {a}指series.name  {b}指series.data的name
							// {c}指series.data的value  {d}%指这一部分占总数的百分比
							formatter: '{b} : {c}个 '
						},
						textStyle: {
							color: '#595959',	// 提示文字颜色
							fontSize: 18,		// 提示文字大小
						}
					},
					data:this.resourcedirect,
					}
				]
				};
			myChart.setOption(option);
		});
	},
	tzqcalin(){
		this.qcdialogTableVisible = true
		this.qclistcover()
		this.$nextTick(() => {
			var myChart = this.$echarts.init(document.getElementById('main'));
			//配置图表
			var option = {
				tooltip: {
					trigger: 'item'
				},
				legend: {
					bottom: '-2%',
					left: 'center',
					itemWidth: 20,
					itemHeight: 10,
					textStyle: {
					color: '#fff'  // 图例文字颜色
					},
				},
				series: [
					{
					name: '',
					type: 'pie',
					radius: ['40%', '50%'],
					avoidLabelOverlap: false,
					itemStyle: {
						// borderRadius: 10,
						// borderColor: '#fff',
						// borderWidth: 2
					},
					label: {
						show: false,
						position: 'center'
					},
					emphasis: {
						label: {
						show: false,
						fontSize: 40,
						fontWeight: 'bold'
						}
					},
					labelLine: {
						show: true
					},
					labelLine: {
						normal: {
							show: true   // show设置线是否显示，默认为true，可选值：true ¦ false
						}
					},
					// 设置值域的标签
					label: {
						normal: {
							position: 'outer',  // 设置标签位置，默认在饼状图外 可选值：'outer' ¦ 'inner（饼状图上）'
							// formatter: '{a} {b} : {c}个 ({d}%)'   设置标签显示内容 ，默认显示{b}
							// {a}指series.name  {b}指series.data的name
							// {c}指series.data的value  {d}%指这一部分占总数的百分比
							formatter: '{b} : {c}个 '
						},
						textStyle: {
							color: '#595959',	// 提示文字颜色
							fontSize: 18,		// 提示文字大小
						}
					},
					data:this.resourcedirect,
					}
				]
				};
			myChart.setOption(option);
		});
	},
	fwlistcover(){
		ntfmlist().then(res=>{
			this.ntfmnumblist = res.result.records
			this.ntfmnumbtotal = res.result.total
		})
	},
	qclistcover(){
		fwlist().then(res=>{
			this.qcfmnumblist = res.result.records
			this.qcfmnumbtotal = res.result.total
		})
	},
	getNewestDataList(){
		getNewestData().then(res=>{
			this.listData = res.result.HisSurvData
		})
	},
	getPollutionDictList(){
		getPollutionDict().then(res=>{
			this.getPollutionDictCover = res.result
		})
	},
	 handleClick(tab, event) {
		let stationCode = ''
		getIndexSummry(stationCode).then(res=>{
			if (res.code ='200') {
				this.NOSummry = res.result[tab.index].NOSummry
				this.TNSummry = res.result[tab.index].TNSummry
				this.TPSummry = res.result[tab.index].TPSummry
				this.NHSummry = res.result[tab.index].NHSummry
				this.CODSummry = res.result[tab.index].CODSummry
				this.dataStr = res.result[4].dataStr
			}
			this.$nextTick(()=> { // 加载echarts图
				this.zxong()
				this.zxtwoong()
			})
		})
      },
	getIndexSummrycover(){
		let stationCode = ''
		getIndexSummry(stationCode).then(res=>{
			if (res.code ='200') {
				this.NOSummry = res.result[0].NOSummry
				this.TNSummry = res.result[0].TNSummry
				this.TPSummry = res.result[0].TPSummry
				this.dataStr = res.result[4].dataStr
				if (this.NOSummry ) {
					this.$nextTick(()=> { // 加载echarts图
						setTimeout(() =>{
							this.zxong()
						}, 1000)
					})
				}
			}
		})
	},
	// 折线图数据
	zxong(){
		this.$nextTick(() => {
			var myChartLine = this.$echarts.init(document.getElementById('myChart'));
			var optionLine = {
			tooltip: {
				trigger: 'axis'
			},
			grid: {
				top:'14%',
				left: '3%',
				right: '4%',
				bottom: '9%',
				containLabel: true
			},
			toolbox: {
			},
			legend: {
				padding:[10,50,0,0],
					x:'right',      //可设定图例在左、右、居中
					y:'top',     //可设定图例在上、下、居中
				data: ['总氮', '总磷', '硝态氮'],
				textStyle:{
				fontSize: 12,//字体大小
				color: '#ffffff'//字体颜色
				},
				},
			xAxis: {
				type: 'category',
				boundaryGap:false,
				// data:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
				data:this.dataStr,
				axisLine: {
				lineStyle: {
				color: '#2295ee', //x轴的颜色
				width: 1, //轴线的宽度
				},
				},
			},
			yAxis: {
				type: 'value',
				/*min:0,
				max:60,
				splitNumber:6*/
				splitLine: {
				lineStyle: {
				// 设置背景横线
				color: '#2295ee'
				}
				},
				axisLine: {
				lineStyle: {
				color: '#2295ee', //x轴的颜色
				width: 1, //轴线的宽度
				},
				},
			},
			series: [
				{
				name:'总氮',
				type:'line',
				stack: '总量1',
				data:this.TNSummry,
				lineStyle: {
					color: '#00FF89' // 设置线的颜色为红色
				},
				smooth:true,
				},
				{
				name:'总磷',
				type:'line',
				stack: '总量1',
				data:this.TPSummry,
				lineStyle: {
					color: '#FF0D00' // 设置线的颜色为红色
				},
				smooth:true,
				},
				{
				name:'硝态氮',
				type:'line',
				stack: '总量1',
				data:this.NOSummry,
				lineStyle: {
					color: '#FFC100' // 设置线的颜色为红色
				},
				smooth:true,
				}
			]
			};
			//为echarts对象加载数据
			myChartLine.setOption(optionLine);
		})

	},
	zxtwoong(){
		this.$nextTick(() => {
			var zlxgmyChartLine = this.$echarts.init(document.getElementById('zlxgChart'));
			var zlxgoptionLine = {
			tooltip: {
				trigger: 'axis'
			},
			grid: {
				top:'14%',
				left: '3%',
				right: '4%',
				bottom: '9%',
				containLabel: true
			},
			toolbox: {
			},
			legend: {
				padding:[10,50,0,0],
					x:'right',      //可设定图例在左、右、居中
					y:'top',     //可设定图例在上、下、居中
				// data: ['总氮', '总磷', '硝态氮'],
				textStyle:{
				fontSize: 12,//字体大小
				color: '#ffffff'//字体颜色
				},
				},
			xAxis: {
				type: 'category',
				boundaryGap:false,
				data:this.dataStr,
				axisLine: {
				lineStyle: {
				color: '#2295ee', //x轴的颜色
				width: 1, //轴线的宽度
				},
				},
			},
			yAxis: {
				type: 'value',
				/*min:0,
				max:60,
				splitNumber:6*/
				splitLine: {
				lineStyle: {
				// 设置背景横线
				color: '#2295ee'
				}
				},
				axisLine: {
				lineStyle: {
				color: '#2295ee', //x轴的颜色
				width: 1, //轴线的宽度
				},
				},
			},
			series: [
			{
				name:'总氮',
				type:'line',
				stack: '总量1',
				data:this.TNSummry,
				lineStyle: {
					color: '#009DB2' // 设置线的颜色为红色
				},
				smooth:true,
			},
			{
				name:'总磷',
				type:'line',
				stack: '总量1',
				data:this.TPSummry,
				lineStyle: {
					color: '#00FF89' // 设置线的颜色为红色
				},
				smooth:true,
			},
			{
				name:' 氨氮',
				type:'line',
				stack: '总量1',
				data:this.NHSummry,
				lineStyle: {
					color: '#FF0D00' // 设置线的颜色为红色
				},
				smooth:true,
			},
			{
				name:' 化学需氧量',
				type:'line',
				stack: '总量1',
				data:this.CODSummry,
				lineStyle: {
					color: '#FFC100' // 设置线的颜色为红色
				},
				smooth:true,
			}
		]
			};
			//为echarts对象加载数据
			zlxgmyChartLine.setOption(zlxgoptionLine);
		})

	},
    // 实时时间
     getNowTime () {
      let speed = 1000
      let that = this
      let theNowTime = function () {
        that.nowTime = that.timeNumber()
      }
      setInterval(theNowTime, speed)
    },
    timeNumber () {
      let today = new Date()
      let date = today.getFullYear() + '年' + this.twoDigits(today.getMonth() + 1) + '月' + this.twoDigits(today.getDate())+ '日'
      let time = this.twoDigits(today.getHours()) + ':' + this.twoDigits(today.getMinutes()) + ':' + this.twoDigits(today.getSeconds())
	  let wk = new Date().getDay()
	  let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
	  let week = weeks[wk]
      return date + '  ' + week + '  ' + time
    },
    twoDigits (val) {
      if (val < 10) return '0' + val
      return val
    },
	// 跳转页面3
	addTask3(e) {
		console.log("tdjdnhdjdn",e)
		let stationCode = e.stationCode
		if (e.stationType=='livestock') {
			this.$router.push({path: '/CqyzList',query:{stationCode: stationCode}})
			// this.$message({
			// 	message: '站点正在建设中......',
			// 	showClose: true,
			// });
		} else {
			this.$router.push({path: '/MywrList',query:{stationCode: stationCode}})
		}
	},
	// 弹窗退出登录
	handleClosetuichu(done) {
		this.dialogVisible = false
        window.sessionStorage.clear();
		this.$router.push({path: '/Login'})
    },
	// 弹窗个人信息
	infor(){
		var onformation = JSON.parse(window.sessionStorage.getItem('password'));
		  this.inforname = onformation.realname
	},
	guanbi(){
		this.dialogTableVisible = false
	},
	qcguanbi(){
		this.qcdialogTableVisible = false
		this.qyinfordtdialogTableVisible = false
		this.trqxzxdialogTableVisible = false
	},
	screen() {
		let element = document.documentElement;
		if (this.fullscreen) {
			if (document.exitFullscreen) {
			document.exitFullscreen();
			} else if (document.webkitCancelFullScreen) {
			document.webkitCancelFullScreen();
			} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
			} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
			}
		} else {
			if (element.requestFullscreen) {
			element.requestFullscreen();
			} else if (element.webkitRequestFullScreen) {
			element.webkitRequestFullScreen();
			} else if (element.mozRequestFullScreen) {
			element.mozRequestFullScreen();
			} else if (element.msRequestFullscreen) {
			// IE11
			element.msRequestFullscreen();
			}
		}
		this.fullscreen = !this.fullscreen;
	},
  },
}
</script>

<style scoped>
@import '@/assets/css/index.css';
.quanping{
	width: 1.6vw;
	margin-right: 3%;
	margin-top: 0.2vw;
	position: absolute;
	right: 1%;
	z-index: 999;
}
/* 台账弹窗 */
.tzsanguang{
	position: absolute;
    top: -1.5vw;
    right: 38%;
}
.tzsanguangbt{
	position: absolute;
    bottom: -1.5vw;
    right: 38%;
}
.tz_cov{
	width: 100%;
	height: 30vw;
	margin: 0 auto;
	/* margin-top: 4vw; */
	border: 1px solid #0169d0;
    position: relative;
    background: rgba(16, 45, 80, 1);
    box-shadow: inset 0px 0px 16px 0px rgba(0, 145, 255, 1);
}
.tz_cov_top{
	width: 40%;
	height: 3vw;
	margin: 0 auto;
	background: url(../../assets/image/tz_tltle_bg.png)no-repeat;
	background-size: cover;
	text-align: center;
	line-height: 3vw;
	margin-bottom: 2vw;
}
.tz_cov_top_tit{
	font-size: 1vw;
    color: #fff;
    text-shadow: 0px 2px 0px rgba(4,49,52,0.55);
    font-family: douyuFont;
}
.tz_cov_left{
	width: 35%;
	float: left;
}
.tz_cov_right{
	width: 65%;
	float: right;
}
.tz_cov_left_tit{
	width: 11vw;
	height: 2vw;
	text-align: left;
	padding-left: 3%;
	line-height: 2vw;
	background: url(../../assets/image/tz_title2_bg.png)no-repeat;
	background-size: cover;
	margin-left: 2vw;
}
.tz_cov_left_tit_text{
	font-size: 0.9vw;
	font-weight: 500;
	color: #E5EEFA;
}
.tz_cov_left_bincov{
	position: relative;
	width: 100%;
	height: 16vw;
}
.tz_cov_left_bin{
	width: 9vw;
	height: 9vw;
	background: url(../../assets/image/tz_binzhuangtu_bg.png)no-repeat;
	background-size: cover;
	position: absolute;
	top: 42px;
	left:28.5%;
	z-index: 1;
}
.tz_cov_left_bineca{
	width: 100%;
	height: 13.5vw;
	position: absolute;
	top: 0;
	left: 0%;
	z-index: 2;
}
.tz_cov_right_num{
	font-size: 0.9vw;
	float: right;
	font-weight: 500;
	color: #B3C1D1;
	margin-right: 3%;
}
.tz_cov_right_num_big{
	color: #FFF220;
	font-size: 1.5vw;
	font-family: HYChangLiSongKeBen;
}
.tz_cov_right_tab{
	width: 92%;
	height: 20vw;
	overflow: auto;
	display: block;
	margin-left: 5%;
	margin-top: 1vw;
}
/* 定义滚动条样式 */
.tz_cov_right_tab::-webkit-scrollbar {
		width: 2px;
		height: 2px;
		background-color: rgba(240, 240, 240, 0.1);
}
/*定义滚动条轨道 内阴影+圆角*/
.tz_cov_right_tab::-webkit-scrollbar-track {
		box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
		border-radius: 10px;
		background-color: rgba(240, 240, 240, .5);
}
/*定义滑块 内阴影+圆角*/
.tz_cov_right_tab::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset 0 0 0px rgba(87, 146, 255, .8);
		background-color: rgba(87, 146, 255, .8);
}
.tz_cov_right_tab_tit{
	width: 100%;
	height:1.8vw ;
	background: rgba(27,63,112,1);
	font-size: 0.8vw;
	font-weight: 400;
	color: #5792FF;
	position: sticky;
	top: -3px; /* 固定表头位置 */
}
.tz_cov_right_tab_tit th{
	width: 20%;
	height:1.8vw ;
	border: 1px solid #285A93;
}
.tz_cov_right_tab_list{
	width: 100%;
	height:1.8vw ;
	background: rgba(80,131,246,0);
	font-size: 0.8vw;
	font-weight: 400;
	color: #5792FF;
}
.tz_cov_right_tab_list td{
	width: 20%;
	height:1.8vw;
	text-align: center;
	border: 1px solid #285A93;
	color: #fff;
}
.mywrcov_onehover{
	cursor: pointer;
}

/* 台账弹窗结束 */

.boxRader {
			position: relative;
			width: 100%;
			height: 80%;
		}

		.centerBox {
			width: 76%;
			margin-right: 15px;
			background: transparent;
		}

		.boxFont {
			font-size: 1.2vw;
		}

		.centerMainBox1 {
			width: 100%;
			height: 60.5%;
		}

		.centerMainBox2 {
			width: 100%;
			height: 38.5%;
			position: relative;
			margin-top: 15px;
		}

		.centerMainBox2 img {
			position: absolute;
			width: 100%;
			height: 100%;
		}

		.boxTitle2 {
			width: 100%;
			height: 10%;
			font-size: .6vw;
			text-align: center;
			line-height: 10%;
			margin-top: 1vw;
			color: #0efcff;
		}

		.boxLis {
			width: 70%;
			height: 1.5vw;
			margin-left: 3vw;
			margin-top: 1vw;
			border-bottom: .02vw solid rgb(40, 229, 233);
		}

		.boxLis>li {
			width: 4vw;
			height: 1.4vw;
			display: block;
			background: rgba(40, 229, 233, .5);
			margin-right: .5vw;
			color: #fff;
			line-height: 1.4vw;
			text-align: center;
			cursor: pointer;
		}

		.active {
			border-bottom: .02vw solid #040A18;
			border-top: .02vw solid rgb(40, 229, 233);
			border-right: .02vw solid rgb(40, 229, 233);
			border-left: .02vw solid rgb(40, 229, 233);
		}

		.contList {
			position: relative;
			width: 70%;
			height: 7vw;
			margin: 1vw auto 0;
		}

		.content1 li {
			display: none;
		}

		.baseBox {
			width: 100%;
			border: none;
			background: none;
		}

		.boxTitle {
			font-size: .8vw;
			width: 38%;
			margin-top: 1vw;
			text-align: center;
		}

		.right {
			float: right;
		}

		.baseBoxLeft {
			width: 22.5%;
			height: 100%;
			position: relative;
			overflow: hidden;
		}

		.baseBoxRight {
			width: 68%;
			height: 100%;
		}
		.maps {
			width: 100%;
			height: 95%;
			margin-top: 2%;
			background: pink;
		}

		.baseBox1,
		.baseBox2,
		.baseBox3 {
			position: relative;
		}

		.baseBox1 img,
		.baseBox2 img,
		.baseBox3 img {
			position: absolute;
			width: 100%;
			height: 100%;
		}

		.csbaseBox1 {
			position: relative;
			z-index: 999;
		}
    .liSpan {
			width: 100%;
			margin-top: 3.2%;
			text-align: center;
			color: rgb(40, 229, 233);
		}

		.liP {
			width: 66%;
			margin: .3vw auto 0;
			text-align: left;
			font-size: .5vw;
			color: rgb(40, 229, 233);
		}

		a:hover {
			text-decoration: none
		}

		.titleName {
			color: #0EFCFF;
		}

		.plant {
			width: 90%;
			height: 5.5vw;
			margin: 2% auto 0;
			display: -webkit-flex;
			display: -moz-flex;
			/* -webkit-justify-content: space-between;
			-moz-justify-content: space-between; */
		}

		.plant>li {
			width: 15%;
			height: 100%;
		}

		.jumps {
			width: 100%;
			height: 100%;
			background: rgba(0, 235, 255, .08);
			display: -webkit-flex;
			/* -webkit-flex-direction: column;
			-webkit-justify-content: space-between; */
			display: -moz-flex;
			/* -moz-flex-direction: column;
			-moz-justify-content: space-between; */
		}

		.jumps>div>img {
			width: .88vw;
			height: .88vw;
			float: right;
		}

		.jumps>p {
			width: 90%;
			font-size: 1.3vw;
			color: #fff;
			margin: auto;
		}

		.jumps>div {
			width: 90%;
			margin: 5% auto 0;
		}

		.jumps>div>span {
			font-size: .6vw;
			color: #0efcff;
			float: left;
		}

		.spans0 {
			width: 80%;
			height: 5%;
			background: #03DA8D;
		}

		.spans1 {
			width: 80%;
			height: 5%;
			background: #0efcff;
		}

		.spans2 {
			width: 80%;
			height: 5%;
			background: #FE0000;
		}

		.spans3 {
			width: 80%;
			height: 5%;
			background: #F78001;
		}

		.boxVideo {
			width: 100%;
			height: 100%;
		}

		.boxVideo video {
			width: 100%;
			height: 100%;
		}

		.baseDatas {
			width: 70%;
			height: 2vw;
			display: -webkit-flex;
			/* -webkit-justify-content: space-between; */
			margin: 1vw 0 0 5vw;
		}

		.baseDatas li {
			width: 10%;
		}

		.baseDatas li a {
			color: #fff;
			font-size: .8vw;
		}

		.active2 a>span {
			color: rgb(40, 229, 233);
		}

		.baseDatas li a:hover>span {
			color: rgb(40, 229, 233);
		}

		.baseDatas li a:hover>.borderRight {
			border-left-color: rgb(40, 229, 233);
		}

		.tableDetalis {
			width: 92%;
			color: #fff;
			margin-left: 2vw;
		}

		.tableDetalis tr {
			height: 1.3vw;
			font-size: .8vw;
		}

		.tableDetalis tr>td {
			text-align: center;
		}

		.tableDetalis tr>td:nth-child(7) {
			text-align: right;
		}

		.borderRight {
			width: 0;
			height: 0;
			float: left;
			border: .4vw solid transparent;
			border-left-color: #fff;
			margin-top: .2vw;
		}

		.borderActive {
			border-left-color: rgb(40, 229, 233);
		}

		.equipment_table {
			width: 85%;
			height: 25%;
			margin: auto;
			text-align: center;
			color: #fff;
			font-size: .5vw;
		}

		.equipment_table tr:nth-child(1) {
			color: #0EFCFF;
		}

		.equipment_table tr {
			border: 1px solid#0EFCFF;
		}

		.equipment_table tr:nth-child(1) {
			font-size: .8vw;
		}

		.equipment_table img {
			width: 1vw;
		}

		.env_table {
			height: 35%;
			margin-top: 5%;
		}

		.env_table td {
			border: 1px solid#0EFCFF;
		}

		.env_table tr:nth-child(1) {
			background: rgba(14, 252, 255, .3);
		}

		.map img {
			width: 80%;
			margin-left: 10%;
		}

		.map img {
			width: 80%;
			margin-left: 10%;
		}

		.mapActived1 {
			position: absolute;
			top: 41.5%;
			left: 61%;
			width: 3.5vw;
			height: 5.5vw;
			border-radius: 90px;
			cursor: pointer;
		}

		.mapActived2 {
			position: absolute;
			top: 60%;
			right: 16%;
			width: 4.5vw;
			height: 2.5vw;
			cursor: pointer;
		}

		.mapActived3 {
			position: absolute;
			top: 57%;
			right: 9%;
			width: 2.5vw;
			height: 7vw;
			cursor: pointer;
		}

		.mapContent1 {
			position: absolute;
			height: 6vw;
			width: 8vw;
			left: 65%;
			top: 33%;
			background: rgba(255, 255, 255, .2);
		}

		.mapContent2 {
			position: absolute;
			height: 6vw;
			width: 8vw;
			left: 70%;
			top: 35%;
			background: rgba(255, 255, 255, .2);
			display: none;
		}

		.mapContent3 {
			position: absolute;
			height: 6vw;
			width: 8vw;
			right: 1%;
			top: 35%;
			background: rgba(255, 255, 255, .2);
			display: none;
		}

		.mapContentFont {
			width: 85%;
			margin-left: 12%;
			font-size: .6vw;
			color: #0efcff;
			letter-spacing: .05vw;
			margin-bottom: .3vw;
		}

		.mapContentFont:nth-child(1) {
			margin-top: .6vw;
		}

		.mapContentFont span {
			color: white;
		}

		.video-js {
			width: 100%;
			height: 100%;
		}

		.video-js:hover .vjs-big-play-button {
			background: none;
		}

		.video-js .vjs-big-play-button:hover {
			background: none;
		}

		.video-js .vjs-big-play-button {
			border: none;
			background: none;
		}
		.noneNumber{
			width: 30%;
			margin: 0 auto;
			display: block;
			margin-top: 60px;
		}
		.in1con_lf_titshow1{
			font-size: 1vw;
			color: #fff;
			text-shadow: 0px 2px 0px rgba(4,49,52,0.55);
			font-family: douyuFont;
			position: absolute;
			top: 0.9vw;
			left: 0%;
			display: block;
			width: 100%;
			text-align: center;
		}
</style>

<style>
.login .el-dialog {
    position: relative;
    margin: 0 auto;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
    box-sizing: border-box;
    width: 50%;
}
.el-dialog {
    position: relative;
    margin: 0 auto;
    background: none;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
    box-sizing: border-box;
    width: 50%;
}
.el-dialog__header{
	display: none;
}
.home_dlhj .el-carousel__arrow{
	top: 9%;
	font-size: 1vw;
}
.home_dlhj .el-carousel__arrow--left{
	left: 0;
}
.home_dlhj .el-carousel__arrow--right{
	right: 0;
}
.el-tabs--card>.el-tabs__header {
  border: none;
}
.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
	text-align: center;
	height: 1.7vw;
	line-height: 1.7vw;
	margin-left: 1%!important;
	border-radius: 0.4vw;
	background: #0e2c3f;
	color: #fff!important;
	border: 1px solid #2fbdff;
	box-shadow: inset 0px 0px 16px 0px rgba(0, 145, 255, 1);
	margin-top: 8px;
	text-align: center;
	font-size: 12px!important;
}
.el-tabs--card>.el-tabs__header .el-tabs__item{
	text-align: center;
	height: 1.7vw;
	line-height: 1.7vw;
	margin-left: 1%!important;
    border-radius: 0.4vw;
    background: #0e2c3f;
    color: #fff!important;
	font-size: 8px!important;
	border: 1px solid #0e2c3f;
	font-size: 12px!important;
}
.el-tabs--card>.el-tabs__header .el-tabs__nav{
	border: none;
	float:right;
	margin-right: 6%;
	margin-top: 5px;
}
.zlxg_btm .el-tabs__item:hover{
	text-align: center;
	height: 1.7vw;
	line-height: 1.7vw;
	margin-left: 1%!important;
	border-radius: 0.4vw;
	background: #0e2c3f;
	color: #fff!important;
	border: 1px solid #2fbdff;
	box-shadow: inset 0px 0px 16px 0px rgba(0, 145, 255, 1);
	font-size: 12px!important;
}
.v-modal{
	display: none;
}
.jcsssj_rt_num{
	display: inline-block;
	font-size: 0.8vw;
	/* color: #fff; */
	margin-left: 20%;
	text-align: right;
	margin-top: 0.4vw;
}


/* 企业弹窗样式 */
.tctit{
	font-size: 1.2vw;
	color: #fff;
	font-weight: bold;
	display: block;
	margin-bottom: 1.6vw;
}
.tcgldw{
	font-size: 1vw;
	color: #fff;
	line-height: 2vw;
	display: block;
	padding-right: 3%;
	height: 15vw;
	overflow: auto;
}
/* 定义滚动条样式 */
.tcgldw::-webkit-scrollbar {
	width: 2px;
	height: 2px;
	background-color: rgba(240, 240, 240, 0.1);
}
/*定义滚动条轨道 内阴影+圆角*/
.tcgldw::-webkit-scrollbar-track {
	box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
	border-radius: 10px;
	background-color: rgba(240, 240, 240, .5);
}
/*定义滑块 内阴影+圆角*/
.tcgldw::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 0px rgba(87, 146, 255, .8);
	background-color: rgba(87, 146, 255, .8);
}

/* 曲线弹窗 */
.jgdhei{
    margin-top: 2vw;
}
.trwdcov{
    width: 94%;
    margin: 0 auto;
}
.trwdcov_top{
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    text-align: left;
    overflow: hidden;
}
.trwdcov_top_tit{
    color: #fff;
    font-size: 0.8vw;
    margin-right: 1%;
}
.trwdcov_top_zcz{
    color: #fff;
    font-size: 0.8vw;
    float: right;
    margin-right: 3%;
}
.trwdcov_top .el-radio__input.is-checked .el-radio__inner{
    border-color: #00ffcd;
    background: #00ffcd;
}
.trwdcov_top .el-radio__input.is-checked+.el-radio__label{
    color: #00ffcd;
}
.trwdcov_top .el-radio{
    color: #fff;
}
.trwdcov_top .el-input__inner{
    background: none;
}
.trwdcov_top .el-date-editor .el-range-input{
    background: none;
    color: #c9c9c9;
    border-color: #c9c9c9;
}
.trwdcov_top .el-date-editor .el-range-separator{
    color: #fff;
}
.trwdcov .el-table__body, .el-table__footer, .el-table__header{
    width: 100%!important;
}
.trwdcov .el-table__body, .el-table__footer, .el-table__header{
    width: 100%!important;
    text-align: center;
}
.trwdcov .el-table tr{
    background: none;
}
.trwdcov .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
    background: none;
    color: #00ffcd;
    border-bottom: 1px solid #747474;
    text-align: center;
}
.trwdcov .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
    border-right: 1px solid #747474;
}
.trwdcov .el-table--enable-row-transition .el-table__body td.el-table__cell{
    color: #c9c9c9!important;
}
.trwdcov  .el-table--border, .el-table--group{
    border: 1px solid #747474;
}
.trwdcov  .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
    background: rgba(255, 255, 255, 0.2);
}
.trwdcov_top_time{
    margin-right: 3%;
}
.tz_cov_right  .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
	background: rgba(255, 255, 255, 0.1);
	cursor: pointer;
}
.el-table .el-table__cell.gutter {
    background: var(--tableHeadBg);
	width:2px;
}
.el-table__body-wrapper::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background-color: rgba(240, 240, 240, 0.1);
}


.el-table th.gutter{
  display: none;
  width: 0;
}

.el-table colgroup col[name='gutter']{
  display: none;
  width: 0;
}




</style>