新的组件(.vue文件） 中
 
<template>
  <div>
    <div id="he-plugin-simple"></div>
  </div>
</template>
 
<script>
export default {
  created() {
    window.WIDGET = {   //这里的WIDGET  可以直接整个复制你生成的代码
      'CONFIG': {
        "modules": "01234",
        "background": "5",
        "tmpColor": "BCD3F5",
        "tmpSize": "14",
        "city": "101100805",
        "cityColor": "BCD3F5",
        "citySize": "14",
        "aqiColor": "BCD3F5",
        "aqiSize": "14",
        "weatherIconSize": "24",
        "alertIconSize": "18",
        "padding": "5px 10px 10px 10px",
        "shadow": "0",
        "language": "auto",
        "fixed": "false",
        "vertical": "top",
        "horizontal": "left",
        "key": "185cfb2f004d49969fe27d481eb3fbf6"
      }
    }
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://widget.qweather.net/simple/static/js/he-simple-common.js?v=2.0"'
    document.getElementsByTagName('head')[0].appendChild(script)
  }
}
</script>

 
  <style>
 /* <script src="https://widget.qweather.net/simple/static/js/he-simple-common.js?v=2.0"></script> */
  </style>



