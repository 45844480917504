import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)

import Login from '@/pages/Login'
import Home from '@/pages/Home'
import CqyzList from '@/pages/CqyzList'
import MywrList from '@/pages/MywrList'

export default new VueRouter({
    routes:[
        {
            path:"/",
            component:Home,
            meta: {
             title: '',
             requireAuth: true  // 是否需要判断是否登录,这里是需要判断
            },
            name:"home"
        },
        {
            path:"/Login",
            component:Login,
            meta: {
                title: '',
                requireAuth: ''  // 是否需要判断是否登录,这里是需要判断
            },
            name:"login"
        },
        {
            path:"/cqyzList",
            component:CqyzList,
            meta: {
                title: '',
                requireAuth: true  // 是否需要判断是否登录,这里是需要判断
            },
            name:"cqyzList"
        },
        {
            path:"/mywrList",
            component:MywrList,
            meta: {
                title: '',
                requireAuth: true  // 是否需要判断是否登录,这里是需要判断
            },
            name:"mywrList"
        },
        
        // 重定向
        {
            path:'*',
            redirect:"/Home"
        }
    ]
})



