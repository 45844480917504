<template>
	<ScaleBox
      :width="1920"
      :height="1080"
      bgc="transparent"
      :delay="100"
      :isFlat="true"
    >
  		<div class="main2">
			<video class="fullscreenvideo" id="bgvid"  playsinline="" autoplay="" muted="" loop="">
				<source src="@/assets/image/bg2.mp4" type="video/mp4">
			</video>
			<div class="nav">
				<span class="nav_ti nav_mag">{{DetailList.stationName}}</span>
				<span class="nav_tit">治理效果评价与预警一张图</span>
			</div>
			<div class="nav_btn">
				<div class="btn_left">
					<img src="@/assets/image/return.png" class="returnimg" alt="" onClick="javaScript:history.go(-1)">
					<span class="btn_left_time"> {{ nowTime }}</span> <span class="btn_left_tianq" style="z-index: 9999;"><hefentianqi /></span> 
				</div>
				<div class="btn_right">
					<img src="@/assets/image/quanping.png"  class="quanping" v-if="fullscreen==false" @click="screen" alt="">
					<img src="@/assets/image/quanpingtui.png"  class="quanping"  @click="screen" alt="" v-else>
					<span class="btn_right_time"><img src="@/assets/image/header.png" @click="dialogVisible = true" class="peoimg" alt=""></span>
				</div>
				<!-- 退出登录弹窗 -->
				<div class="login logintc">
					<el-dialog
					title="退出登录"
					:visible.sync="dialogVisible"
					width="10%"
					>
						<span class="infornameclas">{{inforname}}，您好！</span>
						<el-button type="primary" @click="handleClosetuichu" class="logintc_tc">
							退出登录
						</el-button>
					</el-dialog>
				</div>
			</div>
			<div class="content">
				<div class="centerBox">
					<div class="baseBox centerMainBox1" style="height:100%">
						<div class="baseBoxLeft left baseBoxHjleft">
							<img src="@/assets/image/kuang_zuoshang.png" class="in2con_zlf" alt="">
							<img src="@/assets/image/kuang_youshang.png" class="in2con_zys" alt="">
							<img src="@/assets/image/kuang_zuoxia.png" class="in2con_zzx" alt="">
							<img src="@/assets/image/kuang_youxia.png" class="in2con_zyx" alt="">
							<img src="@/assets/image/sanguang.png" class="sanguang" alt="">
							<div>
								<div class="in1con_lf_tit"  style="position: relative;">
									<span class="in1con_lf_titshow">站点信息</span>
									<img src="@/assets/image/title_bg.png" class="in1con_lf_titimg" alt="">
									<!-- <img src="@/assets/image/moreinfor.png" class="in1con_lf_moreimg" alt="" @click="zdinfor"> -->
									<span class="in1con_lf_moreimg" @click="zdinfor">详情>></span>
								</div>
								<div class="jczcov">
									<el-carousel  width="100%" arrow="always" :interval="5000">
										<el-carousel-item class="jczcov_lf" v-for="value in DetailList.deviceList" :key="value.id">
											<div class="jczimg" :id="value.deployCode" ></div>
										</el-carousel-item>
									</el-carousel>
									<!-- <div class="jczcov_lf" v-for="value in DetailList.deviceList" :key="value.id">
										 
										<div class="jczimg" :id="value.deployCode" >
											
										</div>
									</div> -->
									
								</div>
								<div class="jctc_add">
									<img src="@/assets/image/jctcicon1.png" class="jctcicon1" alt="">
									<span class="jctcvban_tit">站点名称：{{DetailList.stationName}}</span>
								</div>
								<div class="jctc_add">
									<img src="@/assets/image/jctcicon2.png" class="jctcicon1" alt="">
									<span class="jctcvban_tit">建站时间：{{DetailList.buildTime}}</span>
								</div>
								<div class="jctc_add">
									<img src="@/assets/image/jctcicon3.png" class="jctcicon1" alt="">
									<span class="jctcvban_tit">站点位置：{{DetailList.stationLocation}} </span>
								</div>
								<!-- <div class="jctc_add">
									<img src="@/assets/image/jctcicon2.png" class="jctcicon1" alt="">
									<span class="jctcvban_tit">监测范围：{{DetailList.survRange}}</span>
								</div> -->
								<div class="jctc_add" style="height:6vw;margin-top:0vw;">
									<img src="@/assets/image/jctcicon4.png" class="jctcicon1" alt="">
									<span class="jctcvban_tit">监测指标</span>
									<span class="jctc_text" v-for="(item,index) in DetailList.survColorItems" :key="index" :style="{background:(item.color)}" style="background: #445fb4;margin-top:0.8vw;padding-right:10px;">{{item.name}}</span>
									<!-- <span class="jctc_text" style="background: #52ac2a;">土壤墒情</span>
									<span class="jctc_text" style="background: #ac4f2a;">气象环境</span> -->
								</div>
							</div>
							<div style="background: #0d4e62;height: 1px;width: 94%;margin: 0 auto;margin-top: 1.15vw;"></div>
							<div style="margin:1vw 0">
								<div class="in1con_lf_img">
									<div class="in1con_lf_imglf animate__animated animate__delay-1s animate__infinite animate__slow animate__pulse">
										<img src="@/assets/image/sbsl.png" class="in1con_lf_imgone" alt="">
										<span class="in1con_lf_imglf_tit">设备数量</span>
										<span class="in1con_lf_imglf_num"><span class="in1con_lf_imglf_num_jix">{{DetailList.deviceCount}}</span>个</span>
									</div>
									<div class="in1con_lf_imglf animate__animated animate__delay-1s animate__infinite animate__slow animate__pulse">
										<img src="@/assets/image/sbsl02.png" class="in1con_lf_imgone" alt="">
										<span class="in1con_lf_imglf_tit">监测项</span>
										<span class="in1con_lf_imglf_num"><span class="in1con_lf_imglf_num_jix">{{DetailList.deviceCount}}</span>项</span>
									</div>
									<div class="in1con_lf_imglf animate__animated animate__delay-1s animate__infinite animate__slow animate__pulse">
										<img src="@/assets/image/sbsl03.png" class="in1con_lf_imgone" alt="">
										<span class="in1con_lf_imglf_tit">监测频率</span>
										<span class="in1con_lf_imglf_num"><span class="in1con_lf_imglf_num_jix">{{DetailList.survFrequency}}</span>天</span>
									</div>
								</div>
							</div>
							<div style="background: #0d4e62;height: 1px;width: 94%;margin: 0 auto;margin-top: 0.4vw;"></div>
							<div>
								<div class="in1con_lf_tit ">
									<span class="in1con_lf_titshow">重要污染实时监测</span>
									<img src="@/assets/image/title_bg.png" class="in1con_lf_titimg" alt="">
								</div>
									<ul class="liMarqueeUp" style="height:10vw;overflow:hidden;" >
										<div @click.stop="handleClick($event)">
											<vue-seamless-scroll :data="DetailList.latestData" class="wp-1 hp-1" :class-option="classOption">
													<li class="jcsssj2" v-for="(item,index) in DetailList.latestData" :key="index" :data-survItem="item.survItem">
														<img src="@/assets/image/xian2.png" class="jcsssj_xian2" alt="">
														<div class="jcsssj_rt" style="display:block;" >
															<!-- @click="tczxtc(item.survItem)" -->
															<div style="float: left;width: 50%;" >
																<span class="jcsssj_rtbiao" :style="{background:(item.color)}">{{item.name}}</span>
																<span class="jcsssj_rt_num"  ><span class="in1con_lf_imglf_num_jix"  >{{item.value}}</span>{{item.unit}}</span>
																<!-- <img src="@/assets/image/up.png" class="jcsssj_rt_up" alt=""> -->
															</div>
															<span class="jcsssj_rt_time">{{item.dataDateTime}}</span>
														</div>
													</li>
											</vue-seamless-scroll>
										</div>
									</ul>
							</div>
						</div>
            			<div class="zlxg_btm">
								<img src="@/assets/image/kuang_zuoshang.png" class="in2con_zlf" alt="">
								<img src="@/assets/image/kuang_youshang.png" class="in2con_zys" alt="">
								<img src="@/assets/image/kuang_zuoxia.png" class="in2con_zzx" alt="">
								<img src="@/assets/image/kuang_youxia.png" class="in2con_zyx" alt="">
								<img src="@/assets/image/sanguang.png" class="sanguang2" alt="">
								<img src="@/assets/image/sanguang.png" class="sanguangbt2" alt="">
								<div class="zywr_lf_tit " >
									<span class="zywr_titshow">重要污染物趋势图</span>
									<!-- <div class="zywr_titrt">
										<img src="@/assets/image/zywricon1.png" class="zywricon1" alt="">
										<span class="zywricon1_tit">总磷</span>
										<img src="@/assets/image/zywricon2.png" class="zywricon1" alt="">
										<span class="zywricon1_tit">总氮</span>
										<img src="@/assets/image/zywricon3.png" class="zywricon1" alt="">
										<span class="zywricon1_tit">硝态氮</span>
									</div> -->
								</div>
								<div id="chart1"></div>
							</div>
					</div>
				</div>
				<div class="leftBox right baseBoxHjleft" style="width:22.5%">
					<img src="@/assets/image/kuang_zuoshang.png" class="in2con_zlf" alt="">
					<img src="@/assets/image/kuang_youshang.png" class="in2con_zys" alt="">
					<img src="@/assets/image/kuang_zuoxia.png" class="in2con_zzx" alt="">
					<img src="@/assets/image/kuang_youxia.png" class="in2con_zyx" alt="">
					<img src="@/assets/image/sanguang.png" class="sanguang" alt="">
					<img src="@/assets/image/sanguang.png" class="sanguangbt" alt="">
					<div>
						<div class="home_dlhj" style="position:relative;">
							<div class="in1con_lf_tit ">
								<span class="in1con_lf_titshow">治理效果综合评价</span>
								<img src="@/assets/image/title_bg.png" class="in1con_lf_titimg" alt="">
							</div>
							<img src="@/assets/image/zhpjimg2.png" class="zhpjimg2" alt="">
							<span class="zhpjtit" >{{DisplayListdetailList.detailValue}}</span>
						</div>
					</div>
					<div style="background: #0d4e62;height: 1px;width: 94%;margin: 0 auto;margin-top: 0.4vw;"></div>
					<div>
						<div class="in1con_lf_tit ">
							<span class="in1con_lf_titshow">土壤数据实时监测</span>
							<img src="@/assets/image/title_bg.png" class="in1con_lf_titimg" alt="">
						</div>
						<div class="mywrcov">
							<div class="mywrcov_lf">
								<div class="mywrcov_lfposi">
									<span class="mywrcov_lf_num">0CM</span>
									<span class="mywrcov_lf_hen">-</span>
									<img src="@/assets/image/jcxianimg2.png" class="jcxianimg2" alt="">
									<img src="@/assets/image/jiaintou2.png" class="jiaintou2" alt="">
								</div>
								<div class="mywrcov_lfposi">
									<span class="mywrcov_lf_num">20CM</span>
									<span class="mywrcov_lf_hen">-</span>
									<img src="@/assets/image/jcxianimg2.png" class="jcxianimg2" alt="">
									<img src="@/assets/image/jiaintou2.png" class="jiaintou2" alt="">
								</div>
								<div class="mywrcov_lfposi">
									<span class="mywrcov_lf_num">40CM</span>
									<span class="mywrcov_lf_hen">-</span>
									<img src="@/assets/image/jcxianimg2.png" class="jcxianimg2" alt="">
									<img src="@/assets/image/jiaintou2.png" class="jiaintou2" alt="">
								</div>
								<div class="mywrcov_lfposi">
									<span class="mywrcov_lf_num">60CM</span>
									<span class="mywrcov_lf_hen">-</span>
									<img src="@/assets/image/jcxianimg2.png" class="jcxianimg2" alt="">
									<img src="@/assets/image/jiaintou2.png" class="jiaintou2" alt="">
								</div>
							</div>
							<div class="mywrcov_rt">
								<div class="mywrcov_rt_con1">
									<span class="mywrcov_rt_con_tit">土壤温度(℃)</span>
								</div>
								<div class="mywrcov_rt_con1">
									<span class="mywrcov_rt_con_tit">土壤湿度(%ch)</span>
								</div>
								<div class="mywrcov_rt_con1">
									<span class="mywrcov_rt_con_tit">土壤盐分(us/cm)</span>
								</div>
								<div class="mywrcov_rt_con shouxing" @click="tczxtc('dataSoilTemp')">
									<!-- <span class="mywrcov_rt_con_num in1con_lf_imglf_num_jix">{{DetailListSoilData.dataSoilTemp}}</span> -->
									<animate-number  class="mywrcov_rt_con_num in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="DetailSoilTemp" :key="DetailSoilTemp" duration="3000"  v-if="refresh"></animate-number>
									<img src="@/assets/image/sjjcimg2.png" class="sjjcimg2" alt="">
								</div>
								<div class="mywrcov_rt_con shouxing" @click="tczxtc('dataSoilWet')">
									<!-- <span class="mywrcov_rt_con_num in1con_lf_imglf_num_jix">{{DetailListSoilData.dataSoilWet}}</span> -->
									<animate-number  class="mywrcov_rt_con_num in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="DetailSoilWet" :key="DetailSoilWet" duration="3000" v-if="refresh"></animate-number>
									<img src="@/assets/image/sjjcimg2.png" class="sjjcimg2" alt="">
								</div>
								<div class="mywrcov_rt_con shouxing" @click="tczxtc('dataSoilDdl')">
									<!-- <span class="mywrcov_rt_con_num in1con_lf_imglf_num_jix">{{DetailListSoilData.dataSoilDdl}}</span> -->
									<animate-number  class="mywrcov_rt_con_num in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="DetailSoilDdl" :key="DetailSoilDdl" duration="3000" v-if="refresh"></animate-number>
									<img src="@/assets/image/sjjcimg2.png" class="sjjcimg2" alt="">
								</div>
								<div class="mywrcov_rt_con shouxing" @click="tczxtc('dataSoilTemp2')">
									<!-- <span class="mywrcov_rt_con_num in1con_lf_imglf_num_jix">{{DetailListSoilData.dataSoilTemp2}}</span> -->
									<animate-number  class="mywrcov_rt_con_num in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="DetailSoilTemp2" :key="DetailSoilTemp2" duration="3000" v-if="refresh"></animate-number>
									<img src="@/assets/image/sjjcimg2.png" class="sjjcimg2" alt="">
								</div>
								<div class="mywrcov_rt_con shouxing" @click="tczxtc('dataSoilWet2')">
									<!-- <span class="mywrcov_rt_con_num in1con_lf_imglf_num_jix">{{DetailListSoilData.dataSoilWet2}}</span> -->
									<animate-number  class="mywrcov_rt_con_num in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="DetailSoilWet2" :key="DetailSoilWet2" duration="3000" v-if="refresh"></animate-number>
									<img src="@/assets/image/sjjcimg2.png" class="sjjcimg2" alt="">
								</div>
								<div class="mywrcov_rt_con shouxing" @click="tczxtc('dataSoilDdl2')">
									<!-- <span class="mywrcov_rt_con_num in1con_lf_imglf_num_jix">{{DetailListSoilData.dataSoilDdl2}}</span> -->
									<animate-number  class="mywrcov_rt_con_num in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="DetailSoilDdl2" :key="DetailSoilDdl2" duration="3000" v-if="refresh"></animate-number>
									<img src="@/assets/image/sjjcimg2.png" class="sjjcimg2" alt="">
								</div>
								<div class="mywrcov_rt_con shouxing" @click="tczxtc('dataSoilTemp3')">
									<!-- <span class="mywrcov_rt_con_num in1con_lf_imglf_num_jix">{{DetailListSoilData.dataSoilTemp3}}</span> -->
									<animate-number  class="mywrcov_rt_con_num in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="DetailSoilTemp3" :key="DetailSoilTemp3" duration="3000" v-if="refresh"></animate-number>
									<img src="@/assets/image/sjjcimg2.png" class="sjjcimg2" alt="">
								</div>
								<div class="mywrcov_rt_con shouxing" @click="tczxtc('dataSoilWet3')">
									<!-- <span class="mywrcov_rt_con_num in1con_lf_imglf_num_jix">{{DetailListSoilData.dataSoilWet3}}</span> -->
									<animate-number  class="mywrcov_rt_con_num in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="DetailSoilWet3" :key="DetailSoilWet3" duration="3000" v-if="refresh"></animate-number>
									<img src="@/assets/image/sjjcimg2.png" class="sjjcimg2" alt="">
								</div>
								<div class="mywrcov_rt_con shouxing" @click="tczxtc('dataSoilDdl3')">
									<!-- <span class="mywrcov_rt_con_num in1con_lf_imglf_num_jix">{{DetailListSoilData.dataSoilDdl3}}</span> -->
									<animate-number  class="mywrcov_rt_con_num in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="DetailSoilDdl3" :key="DetailSoilDdl3" duration="3000" v-if="refresh"></animate-number>
									<img src="@/assets/image/sjjcimg2.png" class="sjjcimg2" alt="">
								</div>
							</div>
						</div>
					</div>
					<div style="background: #0d4e62;height: 1px;width: 94%;margin: 0 auto;margin-top: 0.4vw;"></div>
					<div>
						<div class="in1con_lf_tit ">
							<span class="in1con_lf_titshow">气象数据实时监测</span>
							<img src="@/assets/image/title_bg.png" class="in1con_lf_titimg" alt="">
						</div>
						<div class="qxsjssjc">
							<div class="qxsjssjc_left">
								<div class="qxsjssjc_left_list shouxing" @click="tczxtc('dataWindDirection')">
									<div class="qxsjssjc_left_list_tit">
										风向
									</div>
									<!-- <animate-number  class="qxsjssjc_left_list_show" ref="reNum" from="0" :to="AirDataWind" :key="AirDataWind" duration="3000"></animate-number> -->
									<span class="qxsjssjc_left_list_show" v-if="348.76 < AirDataWind || AirDataWind < 11.25">北风</span>
									<span class="qxsjssjc_left_list_show" v-else-if="11.26 < AirDataWind < 33.75">北东北</span>
									<span class="qxsjssjc_left_list_show" v-else-if="33.76 < AirDataWind < 56.25">东北</span>
									<span class="qxsjssjc_left_list_show" v-else-if="56.26 < AirDataWind < 78.75">东东北</span>
									<span class="qxsjssjc_left_list_show" v-else-if="78.76 < AirDataWind < 101.25">东</span>
									<span class="qxsjssjc_left_list_show" v-else-if="101.26 < AirDataWind < 123.75">东东南</span>
									<span class="qxsjssjc_left_list_show" v-else-if="123.76 < AirDataWind < 146.25">东南</span>
									<span class="qxsjssjc_left_list_show" v-else-if="146.26 < AirDataWind < 168.75">南东南</span>
									<span class="qxsjssjc_left_list_show" v-else-if="168.76 < AirDataWind < 191.25">南</span>
									<span class="qxsjssjc_left_list_show" v-else-if="191.26 < AirDataWind < 213.75">南西南</span>
									<span class="qxsjssjc_left_list_show" v-else-if="213.76 < AirDataWind < 236.25">西南</span>
									<span class="qxsjssjc_left_list_show" v-else-if="236.26 < AirDataWind < 258.75">西西南</span>
									<span class="qxsjssjc_left_list_show" v-else-if="258.76 < AirDataWind < 281.25">西</span>
									<span class="qxsjssjc_left_list_show" v-else-if="281.26 < AirDataWind < 303.75">西西北</span>
									<span class="qxsjssjc_left_list_show" v-else-if="303.76 < AirDataWind < 326.25">西北</span>
									<span class="qxsjssjc_left_list_show" v-else-if="337.5 < AirDataWind < 348.75">北西北</span>
									<span class="qxsjssjc_left_list_show" v-else>静风</span>
								</div>
								<div class="qxsjssjc_left_list shouxing" @click="tczxtc('dataSunFallout')">
									<div class="qxsjssjc_left_list_tit">
										太阳全辐射
									</div>
									<span class="qxsjssjc_left_list_show">
										<!-- <span class="in1con_lf_imglf_num_jix">{{DetailListAirData.dataSunFallout}}</span> -->
										<animate-number  class="in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="AirDataFallout" :key="AirDataFallout" duration="3000"></animate-number>
									w/m²</span>
								</div>
								<div class="qxsjssjc_left_list shouxing" @click="tczxtc('dataRainFall')" style="margin-top:1.5vw;">
									<div class="qxsjssjc_left_list_tit">
										降雨量
									</div>
									<span class="qxsjssjc_left_list_show">
										<!-- <span class="in1con_lf_imglf_num_jix">{{DetailListAirData.dataRainFall}}</span> -->
										<animate-number  class="in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="AirDataRainFall" :key="AirDataRainFall" duration="3000"></animate-number>
									mm/hour</span>
								</div>
							</div>
							
							<div class="qxjcimg2">
								<img src="@/assets/image/qxjcimg2top.png" style="width:90%;margin-left:5%;" alt="">
								<img src="@/assets/image/qxjcimg2.png" style="width:90%;margin-left:5%;" alt="">
							</div>
							<div class="qxsjssjc_left">
								<div class="qxsjssjc_left_list shouxing" @click="tczxtc('dataWindSpeed')">
									<div class="qxsjssjc_left_list_tit">
										风速
									</div>
									<span class="qxsjssjc_left_list_show">
										<!-- <span class="in1con_lf_imglf_num_jix">{{DetailListAirData.dataWindSpeed}}</span> -->
										<animate-number  class="in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="AirDataSpeed" :key="AirDataSpeed" duration="3000"></animate-number>
										M/s</span>
								</div>
								<div class="qxsjssjc_left_list shouxing" @click="tczxtc('dataAirTemp')">
									<div class="qxsjssjc_left_list_tit">
										空气温度
									</div>
									<span class="qxsjssjc_left_list_show">
										<!-- <span class="in1con_lf_imglf_num_jix">{{DetailListAirData.dataAirTemp}}</span> -->
										<animate-number  class="in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="AirDataTemp" :key="AirDataTemp" duration="3000"></animate-number>
										℃</span>
								</div>
								<div class="qxsjssjc_left_list shouxing"  @click="tczxtc('dataAirWet')">
									<div class="qxsjssjc_left_list_tit">
										空气湿度
									</div>
									<span class="qxsjssjc_left_list_show">
										<!-- <span class="in1con_lf_imglf_num_jix">{{DetailListAirData.dataAirWet}}</span> -->
										<animate-number  class="in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="AirDataAirWet" :key="AirDataAirWet" duration="3000"></animate-number>
										%rh</span>
								</div>
								<div class="qxsjssjc_left_list shouxing" @click="tczxtc('dataAirPress')">
									<div class="qxsjssjc_left_list_tit">
										大气压力
									</div>
									<span class="qxsjssjc_left_list_show">
										<!-- <span class="in1con_lf_imglf_num_jix">{{DetailListAirData.dataAirPress}}</span> -->
										<animate-number  class="in1con_lf_imglf_num_jix" ref="reNum" from="0" :to="AirDataAirPress" :key="AirDataAirPress" duration="3000"></animate-number>
										mbar</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 台账禽畜弹窗 -->
			<el-dialog width="60%"  :visible.sync="qczddialogTableVisible" >
				<div class="tz_cov">
					 <img src="@/assets/image/guanbi.png" class="guanbiann" @click="qcguanbi" alt="">
						<div class="tz_cov_top">
							<span class="tz_cov_top_tit">监测站信息</span>
						</div>
						<el-row>
							<el-col :span="12">
								<div class="grid-content bg-purple" style="width:80%;margin-left:10%;">
									<el-carousel indicator-position="outside">
										<el-carousel-item v-for="(item,index) in DetailList.stationPic" :key="index">
											<viewer :images="DetailList.stationPic">
												<img :src="urlimg+item" alt="" style="width:100%;height:100%">
											</viewer>
										</el-carousel-item>
									</el-carousel>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="grid-content bg-purple-light">
									<span class="tctit">{{DetailList.stationName}}</span>
									<span class="tcgldw1">管理单位：{{DetailList.stationIntro}}</span>
									<span class="tcgldw1">建站时间：{{DetailList.buildTime}}</span>
									<span class="tcgldw1">站点介绍：{{DetailList.stationOrg}}</span>
								</div>
							</el-col>
						</el-row>
					</div>
			</el-dialog>
			<!-- 曲线弹窗 -->
			<el-dialog
				width="70%"
				top="16vh"
				:visible.sync="trqxzxdialogTableVisible"
				:modal-append-to-body="false"
			>
				<div class="tz_cov" style="height: 35vw">
					<div style="width:100%;height:100%;margin:0 auto;position: absolute;top:0;left:0;z-index:444;background:rgba(0,0,0,0.4);" v-if="trbgzhuanq"></div>
					<img src="@/assets/image/loading.gif" alt="" style="width:150px;height:150px;margin:0 auto;position: absolute;top:280px;left:45%;z-index: 555;" v-if="trbgzhuanq">
					<img
						src="@/assets/image/guanbi.png"
						class="guanbiann"
						@click="qcguanbi"
						alt=""
					/>
					<div class="tz_cov_top">
						<span class="tz_cov_top_tit">{{ descriptionValue }}</span>
					</div>
					<div class="trwdcov jgdhei">
						<div class="trwdcov_top">
						<span class="trwdcov_top_tit">时间范围</span>
						<el-date-picker
							class="trwdcov_top_time"
							v-model="timetr"
							type="daterange"
							@blur="timetrpicker"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
						>
						</el-date-picker>
						<span class="trwdcov_top_tit">展示方式</span>

						<el-radio-group v-model="radiotr" @change="agreeChange">
							<el-radio label="1">折线</el-radio>
							<el-radio label="2">表格</el-radio>
						</el-radio-group>
						</div>
						<el-table
						:data="formListVlaue"
						v-if="trzx"
						height="480"
						border
						style="margin-top: 1vw; background: none"
						>
						<el-table-column prop="date" label="时间"> </el-table-column>
						<el-table-column prop="name" :label="'数值单位：' + unitValue">
						</el-table-column>
						</el-table>

						<template v-else>
						<div id="maintczxtc" style="width: 100%; height: 500px" v-if="trbg"></div>
						</template>
					</div>
					<img src="@/assets/image/kuang_zuoshang.png" class="in2con_zlf" alt="">
					<img src="@/assets/image/kuang_youshang.png" class="in2con_zys" alt="">
					<img src="@/assets/image/kuang_zuoxia.png" class="in2con_zzx" alt="">
					<img src="@/assets/image/kuang_youxia.png" class="in2con_zyx" alt="">
					<img src="@/assets/image/sanguang.png" class="tzsanguang" alt="">
					<img src="@/assets/image/sanguang.png" class="tzsanguangbt" alt="">
				</div>
			</el-dialog>
		</div>
	 </ScaleBox>	
</template>

<script>
import ScaleBox from 'vue2-scale-box'
import { getStationDetail, getStationDisplay, getIndexSummry, survItemSummary } from '@/api';
import vueSeamlessScroll from 'vue-seamless-scroll'
import { ModelFbx } from "vue-3d-model";
import hefentianqi from '@/pages/weather/weather.vue'
export default {
  components: {
	ModelFbx,
	vueSeamlessScroll,
	hefentianqi,
	ScaleBox
  },
  name: "MywrList",
  data(){
    return {
		urlimg:'https://hjbs.sxcooh.com/zh-api/sys/common/static/',
		player: null,
		enableZ:false, //默认关闭电子放大
		play: false, //默认停止播放
      nowTime: '',
	   DetailList:'',
	   DetailListAirData:'',
	   DetailListSoilData:'',
	  DisplayList:'',
	  DisplayListdetailList:'',
	   inforname:'',
	   dialogVisible: false,
	   qczddialogTableVisible:false,
	   rotation: {
			x: 0,
			y: 450,
			z: 0
		},
		AirDataWind:0,
		AirDataFallout:0,
		AirDataRainFall:0,
		AirDataSpeed:0,
		AirDataTemp:0,
		AirDataAirWet:0,
		AirDataAirPress:0,
		// 土壤
		DetailSoilTemp:0,
		DetailSoilWet:0,
		DetailSoilDdl:0,
		DetailSoilTemp2:0,
		DetailSoilWet2:0,
		DetailSoilDdl2:0,
		DetailSoilTemp3:0,
		DetailSoilWet3:0,
		DetailSoilDdl3:0,
		timer: null,
		refresh: true,
		fullscreen: false, //全屏
		dataStr:'',
		 trqxzxdialogTableVisible:false,
		 trbgzhuanq:false,
		 radiotr: '1',
		 trbg:null,
	  	trzx:false,
		timetr: [new Date(), new Date()],
		descriptionValue:null,
    };
  },
  destroyed() {
//离开页面是销毁
    clearInterval(this.timer);
    this.timer = null;
  },
  created(){
	this.infor()
	let stationCode = this.$route.query.stationCode
	getStationDisplay(stationCode).then(val=>{
		this.DisplayList = val.result
		this.DisplayListdetailList = val.result.effect_assess[0].detailList[2]
	})
	getIndexSummry(stationCode).then(res=>{
		if (res.code ='200'&&res.result!=[]) {
			this.NOSummry = res.result[0].NOSummry
			this.TNSummry = res.result[0].TNSummry
			this.TPSummry = res.result[0].TPSummry
			this.dataStr = res.result[1].dataStr
		}
	})
	this.hhlist()
	// 实现轮询
    this.timer = window.setInterval(() => {
			setTimeout(this.hhlistxunh(), 0);
      }, 5000);
	
	// getStationDetail(stationCode).then(res=>{
	// 		this.DetailList = res.result
	// 		this.DetailListAirData = res.result.latestAirData[0]
	// 		this.AirDataWind = res.result.latestAirData[0].dataWindDirection
	// 		this.AirDataFallout = res.result.latestAirData[0].dataSunFallout
	// 		this.AirDataRainFall = res.result.latestAirData[0].dataRainFall
	// 		this.AirDataSpeed = res.result.latestAirData[0].dataWindSpeed
	// 		this.AirDataTemp= res.result.latestAirData[0].dataAirTemp
	// 		this.AirDataAirWet= res.result.latestAirData[0].dataAirWet
	// 		this.AirDataAirPress= res.result.latestAirData[0].dataAirPress

	// 		// 土壤数据
	// 		this.DetailListSoilData = res.result.latestSoilData[0]
	// 		this.DetailSoilTemp = res.result.latestSoilData[0].dataSoilTemp
	// 		this.DetailSoilWet = res.result.latestSoilData[0].dataSoilWet
	// 		this.DetailSoilDdl = res.result.latestSoilData[0].dataSoilDdl
	// 		this.DetailSoilTemp2 = res.result.latestSoilData[0].dataSoilTemp2
	// 		this.DetailSoilWet2 = res.result.latestSoilData[0].dataSoilWet2
	// 		this.DetailSoilDdl2 = res.result.latestSoilData[0].dataSoilDdl2
	// 		this.DetailSoilTemp3 = res.result.latestSoilData[0].dataSoilTemp3
	// 		this.DetailSoilWet3 = res.result.latestSoilData[0].dataSoilWet3
	// 		this.DetailSoilDdl3 = res.result.latestSoilData[0].dataSoilDdl3
	// 		// 监控
	// 		this.DetailList.deviceList.forEach((itm) => {
	// 			setTimeout(() =>{
	// 				this.player = new EZUIKit.EZUIKitPlayer({
	// 					id: itm.deployCode, // 视频容器ID
	// 					accessToken: itm.ysToken,
	// 					url: itm.deviceUrl,
	// 					// template: "pcLive", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
	// 					audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
	// 					autoplay: true,
	// 				})
	// 			}, 2000)
	// 		})
	// })
  },
   computed: {
		classOption() {
			return {
				step: 0.3, // 数值越大速度滚动越快
				limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
				hoverStop: true, // 是否开启鼠标悬停stop
				direction: 1, // 0向下 1向上 2向左 3向右
				openWatch: true, // 开启数据实时监控刷新dom
				singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
				singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
				waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
			};
		},
	},
  mounted(){
    // 实时时间
    this.getNowTime();
	setTimeout(() =>{
	 this.drawChart();
	}, 1000)
	
  },
  methods:{
   // 所有弹窗
 	handleClick(e){
		const item = e.target.closest(".jcsssj2"); // 定位元素
		if (item) { // 是否是滚动组件的某一行/列
			let survitem  = item.dataset.survitem
			this.tczxtc(survitem)
		}
    },
    tczxtc(survItem){
		this.descriptionValue = ''
      this.stationCode = this.$route.query.stationCode
      this.survItem = survItem
      this.trqxzxdialogTableVisible = true
       this.trbgzhuanq = true
      setTimeout(() =>{
          this.deviceSummaryApi()
          this.trbgzhuanq = false
          this.trbg = true;
        }, 1500)
    },
	agreeChange(val){
        let that = this
        this.trbg = false;
        if (val=='1') {
          this.trbg = true
          this.trbgzhuanq = true
          this.trzx = false
          setTimeout(() =>{
            this.trbgzhuanq = false
            that.$nextTick(() => {
              that.deviceSummaryApi()
                // this.drawChartTc();
            })
          }, 1200)
        } else if(val=='2'){
          this.trbgzhuanq = true
          setTimeout(() =>{
            that.deviceSummaryApi()
            this.trbgzhuanq = false
            this.trzx = true
            this.trbg = false
          }, 1200)
        }

        that.btnstatus=(val==='1')?true:false;
    },
	// 历史数据曲线弹唱
    deviceSummaryApi(){
      let obj = {
        stationCode:this.stationCode,
        endTime:this.endTime,
        startTime:this.startTime,
        survItem:this.survItem,
        // auths:this.auths,
      }
      survItemSummary(obj).then(res=>{
        let that = this
        this.dataListVlaue = res.result.dataList
        this.timeListVlaue = res.result.timeList
        this.formListVlaue = res.result.formList
        this.unitValue = res.result.itemInfo.unit
        // this.limitStrValue = res.result.itemInfo.limitStr
        this.descriptionValue = res.result.itemInfo.description
        if (this.timeListVlaue) {
          setTimeout(() =>{
            that.$nextTick(() => {
              that.drawChartTc();
            })
          }, 700)
        }
      })
    },
	drawChartTc() {
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        let myChart1 = this.$echarts.init(document.getElementById("maintczxtc"));
        // 指定图表的配置项和数据
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#3c6ae1'
              }
            }
          },
          legend: {
            data: ['']
          },
          toolbox: {
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [  // 渐变颜色
              {
                offset: 0,
                color: 'rgba(85,111,253,0.70)',
                },
              {
                offset: 1,
                color: 'rgba(85,111,253,0.20)',
                  },
              ],
              global: false,
              },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: this.timeListVlaue.reverse(),
              axisLabel: {
                //x轴文字的配置
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                //x轴文字的配置
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
            }
          ],
          series: [
            {
              name: this.descriptionValue,
              animationDurationUpdate: 1000, // 更新动画时长为 1s
              animationEasingUpdate: 'quinticInOut', // 更新动画缓动效果为 'quinticInOut'
              type: 'line',
              stack: 'Total',
              smooth:true,
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              lineStyle: {
                color: '#3c6ae1' // 设置线的颜色为红色
              },
              data: this.dataListVlaue.reverse(),
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart1.clear();
        myChart1.setOption(option);
      },
	// 时间
	timetrpicker(data){
      this.startTime = data.displayValue[0],
      this.endTime = data.displayValue[1],
      this.deviceSummaryApi()
    },
	// 弹窗退出登录
	handleClosetuichu(done) {
		this.dialogVisible = false
        window.sessionStorage.clear();
		this.$router.push({path: '/Login'})
    },
	// 弹窗个人信息
	infor(){
		var onformation = JSON.parse(window.sessionStorage.getItem('password'));
		  this.inforname = onformation.realname
	},
    // 实时时间
    getNowTime () {
      let speed = 1000
      let that = this
      let theNowTime = function () {
        that.nowTime = that.timeNumber()
      }
      setInterval(theNowTime, speed)
    },
    timeNumber () {
      let today = new Date()
      let date = today.getFullYear() + '年' + this.twoDigits(today.getMonth() + 1) + '月' + this.twoDigits(today.getDate())+ '日'
      let time = this.twoDigits(today.getHours()) + ':' + this.twoDigits(today.getMinutes()) + ':' + this.twoDigits(today.getSeconds())
	  let wk = new Date().getDay()
	  let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
	  let week = weeks[wk]
      return date + '  ' + week + '  ' + time
    },
    twoDigits (val) {
      if (val < 10) return '0' + val
      return val
    },
	drawChart() {
		 // 折线图1
		let myChart = this.$echarts.init(document.getElementById('chart1'));
		let optionLine = {
		tooltip: {
			trigger: 'axis'
		},
		grid: {
			top:'14%',
			left: '3%',
			right: '4%',
			bottom: '9%',
			containLabel: true
		},
		legend: {
			padding:[10,50,0,0],
				x:'right',      //可设定图例在左、右、居中
				y:'top',     //可设定图例在上、下、居中
			data: ['总氮', '总磷', '硝态氮'],
			textStyle:{
			fontSize: 12,//字体大小
			color: '#ffffff'//字体颜色
			},
			},
		xAxis: {
			type: 'category',
			boundaryGap:false,
			// data:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
			data:this.dataStr,
			axisLine: {
			lineStyle: {
			color: '#2295ee', //x轴的颜色
			width: 1, //轴线的宽度
			},
			},
		},
		yAxis: {
			type: 'value',
			/*min:0,
			max:60,
			splitNumber:6*/
			splitLine: {
			lineStyle: {
			// 设置背景横线
			color: '#2295ee'
			}
			},
			axisLine: {
			lineStyle: {
			color: '#2295ee', //x轴的颜色
			width: 1, //轴线的宽度
			},
			},
		},
		series: [
			{
			name:'总氮',
			type:'line',
			stack: '总量1',
			data:this.TNSummry,
			smooth:true,
			lineStyle: {
				color: '#00FF89' // 设置线的颜色为红色
			},
			},
			{
			name:'总磷',
			type:'line',
			stack: '总量1',
			data:this.TPSummry,
			smooth:true,
			lineStyle: {
				color: '#FF0D00' // 设置线的颜色为红色
			},
			},
			{
			name:'硝态氮',
			type:'line',
			stack: '总量1',
			data:this.NOSummry,
			smooth:true,
			lineStyle: {
				color: '#FFC100' // 设置线的颜色为红色
			},
			}
		]
		};
		//为echarts对象加载数据
		myChart.setOption(optionLine);
	},
	zdinfor(){
		this.qczddialogTableVisible = true
	},
	qcguanbi(){
		this.qczddialogTableVisible = false
		this.trqxzxdialogTableVisible = false
	},
	hhlist(){
		let stationCode = this.$route.query.stationCode
		getStationDetail(stationCode).then(res=>{
				this.DetailList = res.result
				this.DetailListAirData = res.result.latestAirData[0]
				this.AirDataWind = res.result.latestAirData[0].dataWindDirection
				this.AirDataFallout = res.result.latestAirData[0].dataSunFallout
				this.AirDataRainFall = res.result.latestAirData[0].dataRainFall
				this.AirDataSpeed = res.result.latestAirData[0].dataWindSpeed
				this.AirDataTemp= res.result.latestAirData[0].dataAirTemp
				this.AirDataAirWet= res.result.latestAirData[0].dataAirWet
				this.AirDataAirPress= res.result.latestAirData[0].dataAirPress

				// 土壤数据
				this.DetailListSoilData = res.result.latestSoilData[0]
				this.DetailSoilTemp = res.result.latestSoilData[0].dataSoilTemp
				this.DetailSoilWet = res.result.latestSoilData[0].dataSoilWet
				this.DetailSoilDdl = res.result.latestSoilData[0].dataSoilDdl
				this.DetailSoilTemp2 = res.result.latestSoilData[0].dataSoilTemp2
				this.DetailSoilWet2 = res.result.latestSoilData[0].dataSoilWet2
				this.DetailSoilDdl2 = res.result.latestSoilData[0].dataSoilDdl2
				this.DetailSoilTemp3 = res.result.latestSoilData[0].dataSoilTemp3
				this.DetailSoilWet3 = res.result.latestSoilData[0].dataSoilWet3
				this.DetailSoilDdl3 = res.result.latestSoilData[0].dataSoilDdl3
				// 监控
				this.DetailList.deviceList.forEach((itm) => {
					setTimeout(() =>{
						var player = new EZUIKit.EZUIKitPlayer({
							id: itm.deployCode, // 视频容器ID
							accessToken: itm.ysToken,
							url: itm.deviceUrl,
							template: "security", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
							audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
							autoplay: true,
							width:430,
							height:231,
						})
						console.log("ddd",player)
						this.player = player
					}, 2000)
				})
		})
	},
	hhlistxunh(){
		let stationCode = this.$route.query.stationCode
		getStationDetail(stationCode).then(res=>{
				// 土壤数据
				this.DetailSoilTemp = res.result.latestSoilData[0].dataSoilTemp
				this.DetailSoilWet = res.result.latestSoilData[0].dataSoilWet
				this.DetailSoilDdl = res.result.latestSoilData[0].dataSoilDdl
				this.DetailSoilTemp2 = res.result.latestSoilData[0].dataSoilTemp2
				this.DetailSoilWet2 = res.result.latestSoilData[0].dataSoilWet2
				this.DetailSoilDdl2 = res.result.latestSoilData[0].dataSoilDdl2
				this.DetailSoilTemp3 = res.result.latestSoilData[0].dataSoilTemp3
				this.DetailSoilWet3 = res.result.latestSoilData[0].dataSoilWet3
				this.DetailSoilDdl3 = res.result.latestSoilData[0].dataSoilDdl3
				// 移除组件
				this.refresh = false
				// 在组件移除后，重新渲染组件
				// this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
				this.$nextTick(() => {
					this.refresh = true
				})
		})
		    
	},
	screen() {
		let element = document.documentElement;
		if (this.fullscreen) {
			if (document.exitFullscreen) {
			document.exitFullscreen();
			} else if (document.webkitCancelFullScreen) {
			document.webkitCancelFullScreen();
			} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
			} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
			}
		} else {
			if (element.requestFullscreen) {
			element.requestFullscreen();
			} else if (element.webkitRequestFullScreen) {
			element.webkitRequestFullScreen();
			} else if (element.mozRequestFullScreen) {
			element.mozRequestFullScreen();
			} else if (element.msRequestFullscreen) {
			// IE11
			element.msRequestFullscreen();
			}
		}
		this.fullscreen = !this.fullscreen;
	},
  }
}
</script>

<style scoped>
/* .layui-layer-shade{
	display: none;
} */

.quanping{
	width: 1.6vw;
	margin-right: 3%;
	margin-top: 0.2vw;
	position: absolute;
	right: 1%;
	z-index: 999;
}
.fullscreenvideo {
	position: absolute;
	width: 100%;
	height:100%;
}
.videocontainer:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	display: block;
	z-index: -1;
	top: 0;
	left: 0;
}
.in1con_lf_imglf_num_jix{
	font-size: 1vw;
	font-weight: 300;
	font-family: HYChangLiSongKeBen;
	letter-spacing:0.1vw
}

.returnimg{
	float: left;
	width: 2vw;
	margin-left: 3%;
	cursor: pointer;
}
.btn_left_time {
    position      : relative;
    float         : left;
    width         : 42%;
    height        : 1.6vw;
    text-align    : center;
    line-height   : 1.6vw;
    font-size     : .8vw;
    color         : #bcd3f5;
    letter-spacing: .1vw;
    cursor        : pointer;
}
.btn_left_tianq{
	position      : relative;
	float         : left;
	width         : 40%;
	height        : 1.6vw;
	text-align    : left;
	line-height   : 1.6vw;
	font-size     : .8vw;
	color         : #bcd3f5;
	letter-spacing: .1vw;
	cursor        : pointer;
}
.btn_right_time{
	position      : relative;
	float         : right;
	width         : 97%;
	height        : 1.6vw;
	text-align    : right;
	line-height   : 1.6vw;
	font-size     : .8vw;
	color         : #bcd3f5;
	letter-spacing: .1vw;
	cursor        : pointer;
	margin-right: 3%;
}
.peoimg{
	width: 2vw;
	margin-left: 3%;
}
.nav_ti {
  text-align: center;
  line-height: 1vw!important;
  color: #fff;
  font-size: 1.4vw;
  letter-spacing: .2vw;
  text-shadow: 0 6px 12px rgba(0,12,37,.82), 0 1px 0 #004c4e, 0 2px 0 #003435, 0 6px 16px rgba(0,220,228,.58), 1px -3px 12px #00e0ec;
      font-family: douyuFont;
}
.nav_mag {
  margin-top: 1vw;
  display: inline-block;
}
.nav_tit {
  font-size: 0.8vw;
  color: #FFFF90;
  display: block;
  line-height: 5px;
  letter-spacing: .2vw;
  margin-top: 1vw;
  font-weight: bold;
  text-shadow: 0 6px 12px rgba(0,12,37,.82), 0 1px 0 #004c4e, 0 2px 0 #003435, 0 6px 16px rgba(0,220,228,.58), 1px -3px 12px #00e0ec;
}
.baseBoxHjleft{
	border: 1px solid #0169d0;
	position: relative;
	background: rgba(16, 45, 80, 0.9);
	box-shadow: inset 0px 0px 16px 0px rgba(0, 145, 255, 1);
	overflow: hidden;
}

.in2con_zlf{
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
}
.in2con_zys{
	position: absolute;
	top: 0;
	right: 0;
}
.in2con_zzx{
	position: absolute;
	bottom: 0;
	left: 0;
}
.in2con_zyx{
	position: absolute;
	bottom: 0;
	right: 0;
}
.sanguang{
	position: absolute;
	top: -1.5vw;
	    right: 15%;
}
.sanguangbt{
	position: absolute;
	bottom: -1.5vw;
	    right: 15%;
}
.sanguang2{
	position: absolute;
	top: -1.5vw;
	right: 35%;
}
.sanguangbt2{
	position: absolute;
	bottom: -1.5vw;
	    right: 35%;
}
.in1con_lf_tit{
	width: 100%;
	height: 3vw;
	text-align: center;
	overflow: hidden;
	position: relative;
}
.in1con_lf_titshow{
	font-size: 1vw;
	color: #fff;
	text-shadow: 0px 2px 0px rgba(4,49,52,0.55);
	display: block;
	margin-top: 0.9vw;
	font-family: douyuFont;
}
.in1con_lf_titimg{
	width: 50%;
	height: 0.9vw;
	position: absolute;
	top: 1.8vw;
	left: 25%;
}
.in1con_lf_tit_show{
	font-size: 0.6vw;
	color: #0891a1;
	position: absolute;
	top:2.2vw;
	right: 3%;
}
.home_lcgk{
	width: 80%;
	height: 6vw;
	margin: 0 auto;
	position: relative;
	overflow: auto;
}
/* 定义滚动条样式 */
.home_lcgk::-webkit-scrollbar {
		width: 2px;
		height: 2px;
		background-color: rgba(4, 111, 220, 0.1);
}
/*定义滚动条轨道 内阴影+圆角*/
.home_lcgk::-webkit-scrollbar-track {
		box-shadow: inset 0 0 0px rgba(4, 111, 220, .5);
		border-radius: 10px;
		background-color: rgba(4, 111, 220, .5);
}
/*定义滑块 内阴影+圆角*/
.home_lcgk::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset 0 0 0px rgba(4, 111, 220, .8);
		background-color: rgba(4, 111, 220, .8);
}
.home_lcgk p {
    font-size: 0.8vw;
    color: #fff;
    line-height: 27px;
    text-indent: 2rem;
}
.in1con_lf_img{
	width: 100%;
	overflow: hidden;
}
.in1con_lf_imglf{
	width: 33.33%;
	float: left;
}
.in1con_lf_imgone{
	width: 60%;
	margin-left: 20%;
}
.in1con_lf_imglf_tit{
	display:block;
	font-size: 0.6vw;
	color: #bcd3f5;
	text-align: center;
}
.in1con_lf_imglf_num{
	display:block;
	font-size: 0.8vw;
	color: #d8e714;
	text-align: center;
}
.qhhjcov{
	width: 100%;
	overflow: hidden;
}
.qhhjcov_lf{
	width: 33%;
	float: left;
}
.qhhjcov_lfimg{
	margin-top: 0.2vw;
	margin-left: 5%;
}
.qhhjcov_lf_tit{
	font-size: 0.8vw;
	color: #fff;
}
.qhhjcov_time{
	font-size: 1vw;
	color: #00f6ff;
	display: block;
	text-align: center;
}
.sx_cov{
	width: 100%;
	height: 6vw;
	margin: 0 auto;
	border: 1px solid rgba(69,233,248,0.41);
	border-radius: 0.5vw;
	margin-top: 1.5vw;
	box-shadow: inset 0px 0px 16px 0px rgba(11, 153, 173, 1);
	cursor: pointer;
}
.sx_covtit{
	font-size: 1vw;
	font-weight: bold;
	color: #00F6FF;
	display: block;
	text-align: center;
	margin-top: 0.5vw;
}
.sx_cov_lt{
	width: 33.33%;
	text-align: center;
	float: left;
	margin-top: 0.5vw;
}
.sx_cov_lt_tit{
	font-size:0.8vw ;
	color: #ACC5E2;
	display: block;
}
.sx_cov_lt_num{
	color:#00F6FF;
	font-size: 0.9vw;
}
.home_dlhj{
	width: 88%;
	min-height: 2vw!important;
	height: 15vw!important;
	margin: 0 auto;
}
.home_dlhj .layui-carousel{
	background:none;
}
.home_dlhj  .layui-carousel>[carousel-item]:before {
    position: absolute;
    content: '\e63d';
    left: 50%;
    top: 50%;
    width: 0px;
    line-height: 1vw;
    margin: -0.5vw 0 0 -2.5vw;
    text-align: center;
    color: #c2c2c2;
    font-family: layui-icon!important;
    font-size: 30px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	display: none;
}
.home_dlhj .layui-carousel{
	background:none;
}
.home_dlhj .layui-carousel>[carousel-item]>*{
	background:none;
}
.home_dlhj  .layui-carousel>[carousel-item]:before {
    position: absolute;
    content: '\e63d';
    left: 50%;
    top: 50%;
    width: 0px;
    line-height: 1vw;
    margin: -0.5vw 0 0 -2.5vw;
    text-align: center;
    color: #c2c2c2;
    font-family: layui-icon!important;
    font-size: 1.5vw;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	display: none;
}
.jczcov{
	width: 96%;
	height:12vw;
	overflow: hidden;
	margin: 0 auto;
	/* margin-left: 2%; */
}
.jczcov_lf{
	width: 100%;
	height: 100%;
	float: left;
	margin-left: 1%;
	margin-right: 2%;
}
.jczcov_lftit{
	font-size: 0.8vw;
	color: #fff;
	display: block;
	text-align: center;
}
.jczimg{
	width: 100%;
	height: 6vw;
}
.jczbtm{
	width: 100%;
	height: 6vw;
	overflow: hidden;
}
.jczbtm_lf{
	width: 33.3%;
	height: 3vw;
	float: left;
	overflow: hidden;
}
.tpimg{
	width: 1.8vw;
	float: left;
	margin-top: 0.8vw;
}
.jczbtm_lf_rt{
	width: 70%;
	float: right;
}
.jczbtm_lf_rttit{
	display: block;
	text-align: left;
	font-size: 0.8vw;
	margin-top: 0.4vw;
	color: #fff;
}
.jczbtm_lf_rtnum{
	display: block;
	text-align: left;
	font-size: 0.8vw;
	color: #27ddfe;
}
.mywrcov_one{
	width:33%;
	height: 4.5vw;
	position: absolute;
	top: 0.4vw;
	left: 3%;
}
.mywrcov_two{
	width:33%;
	height: 4.5vw;
	position: absolute;
	top: 0.4vw;
	right: 3%;
	text-align: right;
}
.mywrcov_onetit{
	font-size: 0.8vw;
	color: #fff;
	display: block;
}
.mywrcov_onenum{
	font-size: 1.4vw;
	color:#cce21d ;
	display: block;
}
.mywrcov_three{
	width:33%;
	height: 4.5vw;
	position: absolute;
	bottom: 0.4vw;
	left: 3%;
}
.mywrcov_four{
	width:33%;
	height: 4.5vw;
	position: absolute;
	bottom: 0.4vw;
	right: 3%;
	text-align: right;
}
.mywrcovcen{
	width:33%;
	height: 4.5vw;
	position: absolute;
	top: 2.4vw;
	left: 33%;
	text-align: center;
}
.mywrcovcen_tit{
	font-size: 2vw;
	color: #5aff8b;
	display: block;
}
.mywrcovcen_con{
	font-size: 0.6vw;
	color:#fff;
}
.jcsssj{
	width: 94%;
	height:4vw;
	margin: 0 auto;
}
.jcsssj2{
	width: 94%;
	height:3.4vw;
	margin: 0 auto;
}
.jcsssj_xian{
	width: 0.9vw;
	height: 4vw;
	float: left;
}
.jcsssj_xian2{
	width: 0.9vw;
	height: 3.4vw;
	float: left;
}
.jcsssj_rt{
	width: 94%;
	height: 2.4vw;
	background: #0c2139;
	float: right;
}
.jcsssj_rt_tit{
	font-size: 0.8vw;
	display: block;
	width: 100%;
	overflow: hidden;
	color: #fff;
	margin-top: 0.2vw;
	margin-left: 1%;
	margin-bottom: 0.5vw;
}
.jcsssj_rt_time{
	font-size: 0.8vw;
	display: inline-block;
	width: 48%;
	overflow: hidden;
	color: #dbe8fb;
	margin-right:0.2vw;
	/* float:right; */
	margin-top: 0.6vw;
	text-align: right;
}
.jcsssj_rtbiao{
	float: left;
	font-size: 0.8vw;
	color: #fff;
	background:#46a385 ;
	border-radius: 0.2vw;
	padding: 0 0.2vw;
	margin-left: 2%;
	margin-top: 0.6vw;
}
.jcsssj_rt_num{
	display: inline-block;
	font-size: 0.8vw;
	/* color: #fff; */
	margin-left: 5%;
	text-align: right;
	margin-top: 0.4vw;
}
.zlxg{
	position: relative;
}
.jcsssj_rt_up{
	margin-top: 0.7vw;
}
.zlxg_btm {
    width: 69%;
    height: 15vw;
    border: 1px solid #0169d0;
    position: absolute;
    bottom: 0;
    left: 31.5%;
    z-index: 66;
    background: rgba(16, 45, 80, 0.9);
    box-shadow: inset 0px 0px 16px 0px rgba(0, 145, 255, 1);
}
.zywr_lf_tit{
	width: 100%;
	height: 3vw;
	overflow: hidden;
	position: relative;
}
.zywr_titshow{
	font-size: 1vw;
	color: #fff;
	text-shadow: 0px 2px 0px rgba(4,49,52,0.55);
	font-weight: bold;
	margin-top: 0.9vw;
	float: left;
	margin-left: 3%;
}
.zywr_titrt{
	width: 25%;
	height: 3vw;
	float: right;
	line-height: 3vw;
}
.zywricon1{
	width: 1vw;
	margin-top: 1vw;
}
.zywricon1_tit{
	font-size: 0.7vw;
	color: #fff;
	margin-right: 4%;
}
.zlxg_btm  .layui-tab .layui-tab-title li {
    width: 19%;
    margin-left: 2%!important;
    border-radius: 0.4vw;
    background: #0e2c3f;
    color: #fff!important;
}
.zlxg_btm .layui-this{
	width: 19%;
	margin-left: 2%!important;
	border-radius: 0.4vw;
	background: #0e2c3f;
	color: #fff!important;
	border: 1px solid #2fbdff;
	box-shadow: inset 0px 0px 16px 0px rgba(0, 145, 255, 1);
}
.zlxg_btm .layui-this:after {
    border: none!important;
}
.zlxg_btm .layui-tab .layui-tab-title {
    position: relative;
    left: 0;
    height: 40px;
    white-space: nowrap;
    font-size: 0;
    border-bottom-width:0;
    border-bottom-style: solid;
    transition: all .2s;
    -webkit-transition: all .2s;
}
#chart1{height:12vw;}
.scdlist{
	width: 9vw;
	height: 2vw;
	line-height: 2vw;
	background: rgba(0,0,0,0.6);
	border-radius: 0.4vw;
	border: 1px solid #4b677c;
	position: absolute;
	bottom: 16vw;
	left: 0;
}
.scdlist2{
	width: 9vw;
	height: 2vw;
	line-height: 2vw;
	background: rgba(0,0,0,0.6);
	border-radius: 0.4vw;
	border: 1px solid #4b677c;
	position: absolute;
	bottom: 19vw;
	left: 0;
}
.scdlist3{
	width: 9vw;
	height: 2vw;
	line-height: 2vw;
	background: rgba(0,0,0,0.6);
	border-radius: 0.4vw;
	border: 1px solid #4b677c;
	position: absolute;
	bottom: 22vw;
	left: 0;
}
.xcdimg{
	width: 0.9vw;
	margin-top: 0.4vw;
	margin-left: 0.4vw;
	margin-right: 0.4vw;
}
.xcd2img{
	width: 0.9vw;
	margin-top: 0.1vw;
	margin-left: 0.4vw;
	margin-right: 0.4vw;
}
.scdlist_tit{
	font-size: 0.7vw;
	  color: #fff;
	  text-shadow: 2px 2px 0 #163796, 
	               -2px 2px 0 #163796, 
	               2px -2px 0 #163796, 
	               -2px -2px 0 #163796;
}
.jctc{
	width: 17.5vw;
	height: 29vw;
	background: url(../../assets/image/jctc.png)no-repeat;
	background-size: cover;
	position: absolute;
	right: 3%;
	top: 0;
}
.jctcvban{
	width: 90%;
	height: 9vw;
	margin: 0 auto;
	margin-top: 0.4vw;
	background: url(../../assets/image/jctc_banner.png)no-repeat;
	background-size: cover;
	margin-left: 6%;
}
.jctc_add{
	height: 1vw;
	padding-left: 3%;
	margin-top: 0.6vw;
}
.jctcicon1{
	width: 0.7vw;
	margin-top: 0.1vw;
}
.jctcvban_tit{
	font-size: 0.7vw;
	color: #fff;
}
.jctc_text{
	padding: 0.1vw 0.4vw;
	border-radius: 0.2vw;
	background: #46a385;
	margin-left: 0.8vw;
	margin-top: 0vw;
	color: #fff;
}
.ljjr{
	width: 40%;
	margin-left: 30%;
	margin-top: 2vw;
}
.cenimg2{
	width: 94%;
	margin-left: 3%;
	margin-top: 2vw;
}
.no3{
	width: 10%;
	float: left;
}
.no4{
	width: 7%;
	float: left;
	margin-left: 35%;
}
.no5{
	width: 10%;
	float: right;
}
.baseBoxRight_no{
	width: 100%;
	height: 8vw;
	margin-top: 1vw;
}
.baseBoxRight_zhuan{
	width: 100%;
	height: 24vw;
	position: relative;
}
.no3btn{
	width: 80%;
	position: absolute;
	bottom: 0;
	left: 10%;
	z-index: 1;
}
.nohome{
	width: 100%;
	position: absolute;
	bottom: 4vw;
	left: 0%;
	z-index: 2;
}
.zhpjimg2{
	width:94%;
	height: 84%;
	margin-left: 3%;
}
.qxsjssjc{
	width: 100%;
	overflow: hidden;
}
.qxsjssjc_left{
	width: 25%;
	float: left;
}
.qxjcimg2{
	width: 50%;
	height: 15.4vw;
	float: left;
}
.qxsjssjc_left_list{
	width: 4.5vw;
	height: 2.5vw;
	border: 1px solid #2d84c5;
	border-radius: 0.4vw;
	margin-left: 10%;
	text-align: center;
	margin-top: 0.8vw;
}
.qxsjssjc_left_list_tit{
	width: 100%;
	height: 1vw;
	text-align: center;
	line-height: 1vw;
	background-image: linear-gradient(#0F2643, #14446B);
	color: #fff;
	border-radius: 0.4vw 0.4vw 0 0;
}
.qxsjssjc_left_list_show{
	font-size: 0.8vw;
	color: #5bf9ff;
	display: inline-block;
	padding-top: 0.2vw;
}
.mywrcov{
	width: 94%;
  height: 11.5vw;
	margin-left: 3%;
	position: relative;
	background: none;
}
.mywrcov_lf{
	width: 20%;
	height: 14vw;
	float: left;
	overflow: hidden;
	position: absolute;
	top: -1.5vw;
	left: 0;
}
.mywrcov_lfposi{
	position: relative;
	width: 100%;
	height: 3.7vw;
	/* overflow: hidden; */
}
.mywrcov_lf_num{
	position: absolute;
	top: 0;
	left: 0;
	font-size: 0.6vw;
	color: #86ffa2;
}
.mywrcov_lf_hen{
	position: absolute;
	top: 0;
	left: 35%;
	font-size: 0.8vw;
	color: #71d3ff;
}
.jcxianimg2{
	width: 1.6vw;
	position: absolute;
	top: 0;
	left: 45%;
}
.jiaintou2{
	width: 1vw;
	position: absolute;
	bottom: -1.8vw;
	left: 85%;
}
.mywrcov_rt{
	width: 80%;
	float: right;
	overflow: hidden;
}
.mywrcov_rt_con{
	width: 30%;
	height: 3vw;
	float: left;
	position: relative;
	margin-top: 0.7vw;
	margin-left: 3%;
}
.mywrcov_rt_con1{
	width: 33.33%;
	height: 1vw;
	float: left;
	position: relative;
	text-align: center;
}
.sjjcimg2{
	position: absolute;
	bottom:0;
	left: 0;
	width: 100%;
}
.mywrcov_rt_con_tit{
	font-size: 0.7vw;
	color: #fff;
}
.mywrcov_rt_con_num{
	font-size: 1.4vw;
	text-align: center;
	display: block;
}
/* fallback for IE */
.mywrcov_rt_con_num {
  color: #fff;
}
/* gradient text for modern browsers */
@supports (background-clip: text) {
  .mywrcov_rt_con_num {
    background-image: linear-gradient(0deg, #fff, #fff);
    background-clip: text;
    color: transparent;
  }
}












.boxRader {
			position: relative;
			width: 100%;
			height: 80%;
		}

		.centerBox {
			width: 76%;
			margin-right: 15px;
			background: transparent;
		}

		.boxFont {
			font-size: 1.2vw;
		}

		.centerMainBox1 {
			width: 100%;
			height: 60.5%;
		}

		.centerMainBox2 {
			width: 100%;
			height: 38.5%;
			position: relative;
			margin-top: 15px;
		}

		.centerMainBox2 img {
			position: absolute;
			width: 100%;
			height: 100%;
		}

		.boxTitle2 {
			width: 100%;
			height: 10%;
			font-size: .6vw;
			text-align: center;
			line-height: 10%;
			margin-top: 1vw;
			color: #0efcff;
		}

		.boxLis {
			width: 70%;
			height: 1.5vw;
			margin-left: 3vw;
			margin-top: 1vw;
			border-bottom: .02vw solid rgb(40, 229, 233);
		}

		.boxLis>li {
			width: 4vw;
			height: 1.4vw;
			display: block;
			background: rgba(40, 229, 233, .5);
			margin-right: .5vw;
			color: #fff;
			line-height: 1.4vw;
			text-align: center;
			cursor: pointer;
		}

		.active {
			border-bottom: .02vw solid #040A18;
			border-top: .02vw solid rgb(40, 229, 233);
			border-right: .02vw solid rgb(40, 229, 233);
			border-left: .02vw solid rgb(40, 229, 233);
		}

		.contList {
			position: relative;
			width: 70%;
			height: 7vw;
			margin: 1vw auto 0;
		}

		.content1 li {
			display: none;
		}

		.baseBox {
			width: 100%;
			border: none;
			background: none;
		}

		.boxTitle {
			font-size: .8vw;
			width: 38%;
			margin-top: 1vw;
			text-align: center;
		}

		.right {
			float: right;
		}

		.baseBoxLeft {
			width: 30%;
			height: 100%;
			position: relative;
		}

		.baseBoxRight {
			width: 68%;
			height: 100%;
		}

		/* .baseBoxLeft img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		} */

		.maps {
			width: 100%;
			height: 95%;
			margin-top: 2%;
			background: pink;
		}

		.baseBox1,
		.baseBox2,
		.baseBox3 {
			position: relative;
		}

		.baseBox1 img,
		.baseBox2 img,
		.baseBox3 img {
			position: absolute;
			width: 100%;
			height: 100%;
		}

		.csbaseBox1 {
			position: relative;
			z-index: 999;
		}
    .liSpan {
			width: 100%;
			margin-top: 3.2%;
			text-align: center;
			color: rgb(40, 229, 233);
		}

		.liP {
			width: 66%;
			margin: .3vw auto 0;
			text-align: left;
			font-size: .5vw;
			color: rgb(40, 229, 233);
		}

		a:hover {
			text-decoration: none
		}

		.titleName {
			color: #0EFCFF;
		}

		.plant {
			width: 90%;
			height: 5.5vw;
			margin: 2% auto 0;
			display: -webkit-flex;
			display: -moz-flex;
			/* -webkit-justify-content: space-between;
			-moz-justify-content: space-between; */
		}

		.plant>li {
			width: 15%;
			height: 100%;
		}

		.jumps {
			width: 100%;
			height: 100%;
			background: rgba(0, 235, 255, .08);
			display: -webkit-flex;
			/* -webkit-flex-direction: column;
			-webkit-justify-content: space-between; */
			display: -moz-flex;
			/* -moz-flex-direction: column;
			-moz-justify-content: space-between; */
		}

		.jumps>div>img {
			width: .88vw;
			height: .88vw;
			float: right;
		}

		.jumps>p {
			width: 90%;
			font-size: 1.3vw;
			color: #fff;
			margin: auto;
		}

		.jumps>div {
			width: 90%;
			margin: 5% auto 0;
		}

		.jumps>div>span {
			font-size: .6vw;
			color: #0efcff;
			float: left;
		}

		.spans0 {
			width: 80%;
			height: 5%;
			background: #03DA8D;
		}

		.spans1 {
			width: 80%;
			height: 5%;
			background: #0efcff;
		}

		.spans2 {
			width: 80%;
			height: 5%;
			background: #FE0000;
		}

		.spans3 {
			width: 80%;
			height: 5%;
			background: #F78001;
		}

		.boxVideo {
			width: 100%;
			height: 100%;
		}

		.boxVideo video {
			width: 100%;
			height: 100%;
		}

		.baseDatas {
			width: 70%;
			height: 2vw;
			display: -webkit-flex;
			/* -webkit-justify-content: space-between; */
			margin: 1vw 0 0 5vw;
		}

		.baseDatas li {
			width: 10%;
		}

		.baseDatas li a {
			color: #fff;
			font-size: .8vw;
		}

		.active2 a>span {
			color: rgb(40, 229, 233);
		}

		.baseDatas li a:hover>span {
			color: rgb(40, 229, 233);
		}

		.baseDatas li a:hover>.borderRight {
			border-left-color: rgb(40, 229, 233);
		}

		.tableDetalis {
			width: 92%;
			color: #fff;
			margin-left: 2vw;
		}

		.tableDetalis tr {
			height: 1.3vw;
			font-size: .8vw;
		}

		.tableDetalis tr>td {
			text-align: center;
		}

		.tableDetalis tr>td:nth-child(7) {
			text-align: right;
		}

		.borderRight {
			width: 0;
			height: 0;
			float: left;
			border: .4vw solid transparent;
			border-left-color: #fff;
			margin-top: .2vw;
		}

		.borderActive {
			border-left-color: rgb(40, 229, 233);
		}

		.equipment_table {
			width: 85%;
			height: 25%;
			margin: auto;
			text-align: center;
			color: #fff;
			font-size: .5vw;
		}

		.equipment_table tr:nth-child(1) {
			color: #0EFCFF;
		}

		.equipment_table tr {
			border: 1px solid#0EFCFF;
		}

		.equipment_table tr:nth-child(1) {
			font-size: .8vw;
		}

		.equipment_table img {
			width: 1vw;
		}

		.env_table {
			height: 35%;
			margin-top: 5%;
		}

		.env_table td {
			border: 1px solid#0EFCFF;
		}

		.env_table tr:nth-child(1) {
			background: rgba(14, 252, 255, .3);
		}

		.map img {
			width: 80%;
			margin-left: 10%;
		}

		.map img {
			width: 80%;
			margin-left: 10%;
		}

		.mapActived1 {
			position: absolute;
			top: 41.5%;
			left: 61%;
			width: 3.5vw;
			height: 5.5vw;
			border-radius: 90px;
			cursor: pointer;
		}

		.mapActived2 {
			position: absolute;
			top: 60%;
			right: 16%;
			width: 4.5vw;
			height: 2.5vw;
			cursor: pointer;
		}

		.mapActived3 {
			position: absolute;
			top: 57%;
			right: 9%;
			width: 2.5vw;
			height: 7vw;
			cursor: pointer;
		}

		.mapContent1 {
			position: absolute;
			height: 6vw;
			width: 8vw;
			left: 65%;
			top: 33%;
			background: rgba(255, 255, 255, .2);
		}

		.mapContent2 {
			position: absolute;
			height: 6vw;
			width: 8vw;
			left: 70%;
			top: 35%;
			background: rgba(255, 255, 255, .2);
			display: none;
		}

		.mapContent3 {
			position: absolute;
			height: 6vw;
			width: 8vw;
			right: 1%;
			top: 35%;
			background: rgba(255, 255, 255, .2);
			display: none;
		}

		.mapContentFont {
			width: 85%;
			margin-left: 12%;
			font-size: .6vw;
			color: #0efcff;
			letter-spacing: .05vw;
			margin-bottom: .3vw;
		}

		.mapContentFont:nth-child(1) {
			margin-top: .6vw;
		}

		.mapContentFont span {
			color: white;
		}

		.video-js {
			width: 100%;
			height: 100%;
		}

		.video-js:hover .vjs-big-play-button {
			background: none;
		}

		.video-js .vjs-big-play-button:hover {
			background: none;
		}

		.video-js .vjs-big-play-button {
			border: none;
			background: none;
		}
		.zhpjtit{
			position:absolute;left:0;top:5vw;color:#2ff06f;width:100%;text-align:center;font-size:2vw;font-weight:bold;
		}
		.in1con_lf_moreimg{
			position: absolute;
			right: 3%;
			top: 0.8vw;
			z-index: 3;
			cursor: pointer;
			color: #b0bbc2;
		}
		.in1con_lf_moreimg:hover{
			position: absolute;
			right: 3%;
			top: 0.8vw;
			z-index: 3;
			cursor: pointer;
			color: #efff5d;
		}
/* 弹窗样式 */
.tctit{
	font-size: 1.2vw;
	color: #fff;
	font-weight: bold;
	display: block;
	margin-bottom: 1.6vw;
}
.tcgldw{
	font-size: 1vw;
	color: #fff;
	line-height: 2vw;
	display: block;
}
.tcgldw1{
	font-size: 1vw;
	color: #fff;
	line-height: 2vw;
	display: block;
}
.tz_cov{
	width: 100%;
	height: 25vw;
	margin: 0 auto;
	/* margin-top: 4vw; */
	border: 1px solid #0169d0;
    position: relative;
    background: rgba(16, 45, 80, 0.9);
    box-shadow: inset 0px 0px 16px 0px rgba(0, 145, 255, 1);
}
.tz_cov_top{
	width: 40%;
	height: 3vw;
	margin: 0 auto;
	background: url(../../assets/image/tz_tltle_bg.png)no-repeat;
	background-size: cover;
	text-align: center;
	line-height: 3vw;
	margin-bottom: 2vw;
}
.tz_cov_top_tit{
	font-size: 1vw;
    color: #fff;
    text-shadow: 0px 2px 0px rgba(4,49,52,0.55);
    font-family: douyuFont;
}
.guanbiann{
	width: 2vw;
	height: 2vw;
	position: absolute;
	right: 2%;
	top: 1vw;
	cursor: pointer;
}

</style>

