<template>
  <div>
    <!-- <audio class="success"  src="@/assets/image/musin.mp3" autoplay loop></audio> -->
    <video autoplay loop muted preload 
      style="width: 100%; height: 100%; object-fit: fill">
      <source src="@/assets/image/loginback.mp4">
    </video>
    <img src="@/assets/image/logintltle.png" alt="" class="logintltle">
    <el-form
         class="loginipt demo-ruleForm"
        :rules="rules"
        :model="rulesForm"
        status-icon
        ref="ruleForm"
    >
        <img src="@/assets/image/login_title_img.png" alt="" class="login_title_img">
      <el-form-item label="" prop="name" class="loginipt_list1">
        <img src="@/assets/image/login_icon_zhanghu.png" alt="" class="login_icon_zhanghu">
        <el-input type="text" v-model="rulesForm.name" class="login_iptnum" placeholder="请输入账号"></el-input>
      </el-form-item>
      <el-form-item label="" prop="password" class="loginipt_list1">
        <img src="@/assets/image/login_icon_mima.png" alt="" class="login_icon_zhanghu">
        <el-input type="password" v-model="rulesForm.password" class="login_iptnum" placeholder="请输入密码" ></el-input>
      </el-form-item>
      <el-form-item label="" prop="yzm" class="loginipt_list1 posit">
        <img src="@/assets/image/login_icon_mima.png" alt="" class="login_icon_zhanghu">
        <el-input type="text" v-model="rulesForm.yzm" class="login_iptnum" placeholder="请输入验证码" ></el-input>
        <div class="login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img" />
        </div>
      </el-form-item>
      <el-form-item>
        <el-button class="login_btn_anniu" type="primary" @click="submitForm('ruleForm')">登  录</el-button>
      </el-form-item>
    </el-form>
    <!-- <div class="loginipt">
        <img src="@/assets/image/login_title_img.png" alt="" class="login_title_img">
        <div class="loginipt_list1">
            <img src="@/assets/image/login_icon_zhanghu.png" alt="" class="login_icon_zhanghu">
            <input type="text" class="login_iptnum" placeholder="请输入账号">
        </div>
        <div class="loginipt_list1">
            <img src="@/assets/image/login_icon_mima.png" alt="" class="login_icon_zhanghu">
            <input type="text" class="login_iptnum" placeholder="请输入密码">
        </div>
        <div class="login_btn_anniu">
           <span>登录</span>
        </div>
        
    </div> -->
  </div>
</template>

<script>
import { login, getCodeInfo } from '@/api';
 
export default {
  data() {
    return {
      codeUrl:'',
      //存储数据的对象
      rulesForm:{
        name:'',
        password:'',
        yzm:'',
      },
      rules:{
        // name:[
        //   {required:true,message:'请输入账号',trigger:"blur"}
        // ],
        // password:[
        //   {required:true,message:'请输入密码',trigger:"blur"}
        // ],
        // yzm:[
        //   {required:true,message:'请输入验证码',trigger:"blur"}
        // ]
      }
    };
  },
  created(){
     this.getCode()
    // 设置整点定时发送请求
    setInterval(() => {
      this.getCode()
    }, 30000);
  },
  methods: {
    submitForm(formName){
      if (this.rulesForm.name=='') {
        this.$message.error('请输入账号');
        return;
      }
      if (this.rulesForm.password=='') {
        this.$message.error('请输入密码');
        return;
      }
       if (this.rulesForm.yzm=='') {
        this.$message.error('请输入验证码');
        return;
      }
      this.$refs[formName].validate((valid)=>{
          if(valid){
              //如果校检通过，在这里向后端发送用户名和密码
              login({
                username: this.rulesForm.name,
                password: this.rulesForm.password,
                captcha: this.rulesForm.yzm,
                checkKey:1629428467008,
              }).then((data)=>{
                if(data.code === 200){
                  window.sessionStorage.setItem('token', data.result.token)
                  window.sessionStorage.setItem('password', JSON.stringify(data.result.userInfo));
                  // localStorage.setItem('token',data.result.token);
                    window.location.href= '/Home';
                }else{
                     this.$message.error(data.message)
                }
              });
          }else{
            console.log("error submit!!");
            return false;
          }
      });
    },
     getCode(){
      let now = new Date().getTime();
      getCodeInfo(now).then((res) =>{
        if (res.success == true) {
          this.codeUrl = res.result
          console.log(this.codeUrl)
          // this.codeUrl = res.data.img
        }
      })
    }
  }
}
</script>

<style scoped>
    video{
      z-index:-100;
      width: 100%;
      height:100%;
    }
    .loginipt{
        /* position: absolute;
        top: 28%;
        right: 33%; */
        margin: 0 auto;
        margin-right: 8%;
        margin-top: 17%;
        width: 552px;
        height: 475px;
        background: url(../../assets/image/login_bg_img.png)no-repeat;
	      background-size: 100% 100%;
        overflow: hidden;
    }
    .logintltle{
        position: absolute;
        top: 10%;
        right: 20%;
        width: 60%;
    }
    .login_title_img{
        display: block;
        margin: 0 auto;
        margin-top: 1.6vw;
    }
    .loginipt_list1{
        width: 80%;
        height: 60px;
        margin: 0 auto;
        margin-top: 32px;
        background: #0B2136;
        border: 1px solid #074856;
        box-shadow: inset 0 0 13px 0 rgba(1,9,17,.8);
        border-radius: 4px;
        position: relative;
    }
    .loginipt_list1:hover{
      border: 1px solid #00f6ff;
    }
    .login_btn_anniu{
        width: 50%;
        height: 60px;
        /* line-height: 3vw;s */
        text-align: center;
        margin-top: 24px;
        margin-left: 25%;
        background: url(../../assets/image/login_btn_anniu.png)no-repeat;
        background-size: 100% 100%;
        color: #5cffee;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        border: none;
    }
    .login_btn_anniu:hover{
        width: 50%;
        height: 60px;
        text-align: center;
        margin-top: 24px;
        margin-left: 25%;
        background: url(../../assets/image/login_btn_anniu.png)no-repeat;
        background-size: 100% 100%;
        color: #5cffee;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
        border: none;
        opacity: 0.8;
    }
    .posit{
      position: relative;
    }
    .login_icon_zhanghu{
        width: 20px;
        margin-top: 20px;
        margin-left: 3%;
        float: left;
    }
    .login-code{
      width: 120px;
      height: 56px;
      position: absolute;
      top: 2px;
      right: 2px;
      background: #0B2136;
    }
    .login-code-img{
      width:100%;
      height:100%;
    }
    .login_iptnum{
        width: 88%;
        height: 60px;
        line-height: 60px;
        padding-left: 3%;
        background: none;
        border: none;
        color: #51D4FF;
        font-size: 16px;
    }
    div /deep/ .el-input__inner {
        background-color: RGB(11, 33, 54);
        border: none;
    }
</style>

<style >
.el-input__inner{
  color:#00f6ff!important;
}
   input:-webkit-autofill {

    background: #0B2136 !important;

    color: #00f6ff!important;

     -webkit-box-shadow: 0 0 0px 1000px #0B2136 inset !important;
     -webkit-text-fill-color:#00f6ff; 

   }
   input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {
  -webkit-text-fill-color: #00f6ff !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color:transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s; 
  }
video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  }
  video::-webkit-media-controls {
  display: none !important;
  }
  video::-webkit-media-controls-enclosure {
  display: none !important;
  }
  video::-webkit-media-controls-overlay-cast-button {
  display: none !important;
}
</style>
